import { FC } from "react";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import Box from "@nubank/nuds-web/components/Box/Box";
import { Container } from "./styles";

interface LoadingIcon {
  primaryColor: string;
  secondaryColor?: string;
  size: string;
}

const LoadingIcon: FC<LoadingIcon> = ({
  primaryColor,
  secondaryColor = "#fff",
  size,
}) => {
  return (
    <Container>
      <Box
        className="loading"
        border={`2px solid ${secondaryColor}`}
        borderTopColor={primaryColor}
        borderRightColor={primaryColor}
        width={spacing(size)}
        height={spacing(size)}
        borderRadius={50}
      />
    </Container>
  );
};

export default LoadingIcon;
