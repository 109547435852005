import { CompactEncrypt, importX509 } from "jose";

export async function encryptedPanJWE(
  pan: string,
  x509Key: string,
  keyFingerprint: string
): Promise<string> {
  const publicKey = await importX509(x509Key, "RSA-OAEP-256");

  return new CompactEncrypt(
    new TextEncoder().encode(`{"acctNumber": "${pan}"}`)
  )
    .setProtectedHeader({
      alg: "RSA-OAEP-256",
      enc: "A128GCM",
      kid: keyFingerprint,
    })
    .encrypt(publicKey);
}

export function callThreeDSMethod(
  threeDSMethodUrl: string,
  threeDSServerTransID: string,
  threeDSMethodNotificationURL: string
) {
  const threeDSMethodData = btoa(
    JSON.stringify({ threeDSServerTransID, threeDSMethodNotificationURL })
  );

  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.id = "fingerprinting-iframe";
  iframe.name = "fingerprinting-iframe";
  document.body.appendChild(iframe);

  const hiddenInput = document.createElement("input");
  hiddenInput.type = "hidden";
  hiddenInput.id = "threeDSMethodData";
  hiddenInput.name = "threeDSMethodData";
  hiddenInput.value = threeDSMethodData;

  const form = document.createElement("form");
  form.method = "POST";
  form.action = threeDSMethodUrl;
  form.target = "fingerprinting-iframe";
  form.appendChild(hiddenInput);

  document.body.appendChild(form);
  try {
    form.onsubmit = (event) => {
      event.preventDefault();
    };
    form.submit();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  document.body.removeChild(form);
}
