import validator from "card-validator";

function clearValue(value: string) {
  return value.replace(/ /g, "").replace(/\//g, "");
}

function removeCharacters(value: string) {
  return value.replace(/\D/g, "");
}

export function extractMonthAndYear(expirationDate: string) {
  const value = clearValue(expirationDate);
  const month = parseInt(value.slice(0, 2), 10);
  const year = parseInt(`20${value.slice(2, 4)}`, 10);
  return { month, year };
}

export function extractPanWithoutSpaces(pan: string) {
  return clearValue(pan);
}

export function extractFirstAndLastName(fullName: string) {
  const [firstName, lastName] = fullName.split(" ");
  return { firstName, lastName };
}

export function extractPhoneWithoutCharacter(phone: string) {
  return removeCharacters(phone);
}

export function extractTaxIdCharacter(taxId: string) {
  return removeCharacters(taxId);
}

export function extractTypeCard(pan: string) {
  const result = validator.number(pan);

  return result?.card?.type!;
}
