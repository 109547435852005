import { create } from "apisauce";
import { Charging, responseToModel } from "../adapters/charging.adapter";
import Env from "../config/Env";
import { formatInMonthAndYear } from "../helpers/formatter";
import { maskPanDetails } from "../helpers/mask";
import { generateRandomId } from "../helpers/tracing";
import TokenizationService, { CardRequest } from "./tokezation.service";
import { ChallengeDetails } from "../hooks/useCharging";
import AmplitudeService from "./amplitude.service";

const correlationId = `LINK.${generateRandomId()}`;
const API = create({
  baseURL: Env.CHECKOUT_URL,
  headers: {
    "X-Correlation-ID": correlationId,
  },
});

export enum CheckoutStatus {
  approved = "approved",
  denied = "denied",
  error = "error",
  challenged = "challenged",
}

export interface CheckoutResponseDetails {
  ui_url?: string;
  encoded_challenge_request?: string;
  failed_reason?: string;
}

interface CheckoutResponse {
  status: CheckoutStatus;
  details?: CheckoutResponseDetails;
}

interface CheckoutResult {
  status: CheckoutStatus;
  details?: ChallengeDetails;
}

interface TransactionAuthenticationResponseDetails {
  failed_reason: string;
}

export interface TransactionAuthenticationResponse {
  status: CheckoutStatus;
  details?: TransactionAuthenticationResponseDetails;
}

interface UniversalLink {
  universal_link: string;
}

export interface InstallmentOption {
  number: number;
  installment_amount: string;
  total_amount: string;
  fee: string;
}

interface Address {
  postcode: string;
  state?: string;
  city?: string;
  line1?: string;
  line2?: string;
}

interface BuyerRequest {
  name: string;
  email: string;
  tax_id: string;
  phone: string;
  fingerprint_id: string;
  installments_number?: number;
  address: Address;
}

export interface Seller {
  name: string;
  tax_id: string;
}
export interface Method {
  method_type: string;
  allowed_installments?: number;
  brands?: string[];
  brcode?: string;
  qrcode_image?: string;
  available: boolean;
  reason?: string;
  enabled?: boolean;
  pass_on_fees_enabled?: boolean;
}

export interface SimpleCharging {
  seller: Seller;
  message?: string;
  payment_methods: Method[];
  amount: string;
  due_date: string;
  installments_options?: InstallmentOption[];
}

export interface OptionsCharging {
  "3ds_enabled": boolean;
  payment_error_screens_enabled: boolean;
}

export interface ChargingResponse {
  charging: SimpleCharging;
  options?: OptionsCharging;
}

export enum GetByPublicIdStatus {
  paid = "paid",
  expired = "expired",
  notFound = "notFound",
  unknown = "unknown",
}

export interface BrowserData {
  browser_language: string;
  browser_accept_header: string;
  browser_user_agent: string;
  browser_java_enabled: string;
  browser_javascript_enabled: string;
  browser_color_depth: string;
  browser_screen_height: string;
  browser_screen_width: string;
  browser_tz: string;
}

const statusCodeToEnum = {
  404: GetByPublicIdStatus.notFound,
  410: GetByPublicIdStatus.expired,
  412: GetByPublicIdStatus.paid,
};

interface SupportedVersionResponse {
  three_ds_server_trans_id: string;
  fingerprint_url?: string;
}

class CheckoutService {
  public static async chargingByPublicId(publicId: string): Promise<Charging> {
    const response = await API.get<ChargingResponse>(`/checkout/${publicId}`);
    if (response.ok) {
      return responseToModel(
        publicId,
        response.data!.charging,
        response.data!.options
      );
    }
    throw (
      statusCodeToEnum[response.status || "unknown"] ||
      GetByPublicIdStatus.unknown
    );
  }

  private static getCardDetails(card: CardRequest) {
    return {
      pan: maskPanDetails(card.card_number, "6_first_and_4_last"),
      encrypted_pan: card.encrypted_pan,
      brand: card.card_brand,
      holder_name: card.holder_name,
      expiration_date: formatInMonthAndYear(
        card.expiration_month,
        card.expiration_year
      ),
    };
  }

  public static async checkout(
    publicId: string,
    referenceId: string,
    card: CardRequest,
    buyer: BuyerRequest,
    browserData: BrowserData,
    threeDsServerTransId?: string
  ): Promise<CheckoutResult> {
    const token = await TokenizationService.tokenize(card);

    const response = await API.post<CheckoutResponse>(
      `/checkout/${publicId}`,
      {
        card: { token, ...this.getCardDetails(card) },
        reference_id: referenceId,
        browser_data: browserData,
        three_ds_server_trans_id: threeDsServerTransId,
        buyer,
      },
      {
        headers: {
          "X-Correlation-ID": `${correlationId}.${generateRandomId()}`,
        },
      }
    );

    if (response.ok) {
      if (response.data?.details && response.data.details.ui_url && response.data.details.encoded_challenge_request) {
        return {
          status: response.data!.status,
          details: {
            challengeUrl: response.data.details.ui_url,
            creq: response.data.details!.encoded_challenge_request,
          },
        };
      } 
      
      if (response.data?.details?.failed_reason) {
        return {
          status: CheckoutStatus.denied,
          details: { failed_reason: response.data.details.failed_reason }
        }
      }

      return { status: response.data!.status };
    }

    return {
      status: CheckoutStatus.error,
     };
  }

  public static async createPaymentIntent(
    publicId: string,
    referenceId: string
  ) {
    const response = await API.post<UniversalLink>(
      `/nupay-payment-intent/${publicId}`,
      {
        payment_method: "nupay",
        public_id: publicId,
        reference_id: referenceId,
      }
    );

    if (response.ok) {
      window.open(response.data!.universal_link, "_parent");
      return response.status;
    }

    return CheckoutStatus.error;
  }

  public static async getSupportedVersion(publicId: string, pan: string) {
    const response = await API.post<SupportedVersionResponse>(
      `/supported-version/${publicId}`,
      {
        pan,
      }
    );

    if (response.ok && response.data) {
      return response.data;
    }

    return undefined;
  }

  public static async getTransactionAuthentication(
    publicId: string,
    threeDsServerTransId: string
  ): Promise<TransactionAuthenticationResponse> {
    const response = await API.get<TransactionAuthenticationResponse>(
      `/transaction-authentication/${publicId}/${threeDsServerTransId}`
    );

    if (response.ok && response.data && response.data.details) {
      return {
        status: response.data.status,
        details: {failed_reason: response.data.details.failed_reason}
      }
    }

    if (response.ok && response.data) {
      return {status: response.data.status};
    }

    AmplitudeService.error({
      fingerprint_id: window.document.getElementById("fingerprint_input"),
      entity_name: "get_transaction_authentication",
      public_id: publicId,
      three_ds_server_trans_id: threeDsServerTransId,
      status_code: response.status,
    });

    return {status: CheckoutStatus.error};
  }
}

export default CheckoutService;
