import { FC, useEffect, useState } from "react";

import Box from "@nubank/nuds-web/components/Box/Box";
import Button from "@nubank/nuds-web/components/Button/Button";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";

import Layout from "../../components/Layout";
import Text from "../../resources/text";
import { Container } from "./styles";
import { Charging } from "../../adapters/charging.adapter";
import { IconStore } from "../../components/Layout/styles";
import { ReactComponent as StoreSvg } from "../../assets/svg/store.svg";
import AmplitudeService from "../../services/amplitude.service";

interface PixPayment {
  charging: Charging;
  onClose: () => void;
  reloadCharge: () => void;
}

const PixPayment: FC<PixPayment> = ({ charging, onClose, reloadCharge }) => {
  const { eventProps } = charging;
  const pixPaymentMethod = charging.paymentMethods.find(
    (method) => method.method_type === "pix"
  );
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      reloadCharge();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    AmplitudeService.screenViewed({
      entity_name: "pix_checkout",
      current_screen: "pix_checkout",
      ...eventProps,
    });
  }, []);

  return (
    <Layout
      leadingIcon={{
        iconName: "arrow-left",
        color: "black.defaultT70",
        onClick: onClose,
      }}
    >
      <Container>
        <Box
          display="flex"
          tag="section"
          borderBottom="1px solid #f0f1f5"
          alignItems="center"
          justifyContent="space-between"
          padding={spacing("4x", "6x")}
        >
          <Box display="flex" alignItems="center">
            <IconStore>
              <StoreSvg />
            </IconStore>
            <Typography
              variant="subtitle2"
              marginLeft={8}
              strong
              className="seller-name"
            >
              {charging.seller.name}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="subtitle2"
              color="primary"
              marginLeft={8}
              strong
            >
              {charging.amount}
            </Typography>
          </Box>
        </Box>

        <Box
          tag="section"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          borderBottom="1px solid #f0f1f5"
          alignItems="left"
          gridGap="8x"
          data-testid="pix-payment"
          padding={spacing("12x", "6x", "6x")}
        >
          <Box gridGap="2x" display="grid">
            <Typography variant="heading2" className="body-title">
              {Text.PIX_PAYMENT_TITLE}
            </Typography>
            <Typography variant="paragraph1" className="color-subtitle">
              {Text.PIX_PAYMENT_SUBTITLE}
            </Typography>
          </Box>

          <Box
            className="qrcode-container"
            display="grid"
            justifyContent="center"
            padding={spacing("4x", "4x")}
          >
            <img
              data-testid="qrcode-image"
              alt={pixPaymentMethod?.brcode}
              src={"data:image/bitmap;base64, ".concat(
                pixPaymentMethod?.qrcode_image || ""
              )}
            />
          </Box>

          <Button
            type="button"
            variant="contained"
            styleVariant="primary"
            data-testid="cta"
            extended
            onClick={() => {
              navigator.clipboard.writeText(pixPaymentMethod?.brcode || "");
              if (!hasCopied) {
                AmplitudeService.buttonClicked({
                  entity_name: "copy_qr_code",
                  current_screen: "pix_checkout",
                  ...eventProps,
                });
                setHasCopied(true);
              }
            }}
          >
            {hasCopied
              ? Text.PIX_PAYMENT_INSTRUCTIONS_CTA_AFTER
              : Text.PIX_PAYMENT_INSTRUCTIONS_CTA}
          </Button>
        </Box>

        <Box
          tag="section"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="left"
          gridGap="8x"
          data-testid="pix-payment-instructions"
          padding={spacing("12x", "6x", "12x")}
        >
          <Box gridGap="8x" display="grid">
            <Typography variant="heading4">
              {Text.PIX_PAYMENT_INSTRUCTIONS_TITLE}
            </Typography>

            <Box gridGap="2x" display="grid">
              <Typography variant="paragraph1" className="color-subtitle">
                {Text.PIX_PAYMENT_INSTRUCTIONS_FIRST_ITEM}
              </Typography>
              <Typography variant="paragraph1" className="color-subtitle">
                {Text.PIX_PAYMENT_INSTRUCTIONS_SECOND_ITEM}
              </Typography>
              <Typography variant="paragraph1" className="color-subtitle">
                {Text.PIX_PAYMENT_INSTRUCTIONS_THIRD_ITEM}
              </Typography>
              <Typography variant="paragraph1" className="color-subtitle">
                {Text.PIX_PAYMENT_INSTRUCTIONS_FOURTH_ITEM}
              </Typography>
            </Box>

            <Typography variant="paragraph1" className="color-subtitle">
              {Text.PIX_PAYMENT_INSTRUCTIONS_LAST_PARAGRAPH}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default PixPayment;
