import { FC } from "react";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import Box from "@nubank/nuds-web/components/Box/Box";
import NuLogo from "@nubank/nuds-web/components/NuLogo/NuLogo";
import Icon from "@nubank/nuds-web/components/Icon/Icon";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import { Container, IconStore } from "./styles";
import { ReactComponent as StoreSvg } from "../../assets/svg/store.svg";
import { ReactComponent as LockSvg } from "../../assets/svg/lock.svg";
import Text from "../../resources/text";

interface Layout {
  title?: string;
  leadingIcon?: {
    iconName: string;
    color: string;
    onClick: () => void;
  };
  footerMarginBottom?: string;
}

const Layout: FC<Layout> = ({
  children,
  title,
  leadingIcon,
  footerMarginBottom,
}) => {
  return (
    <Container>
      <Box
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems="center"
        borderBottom="1px solid #f0f1f5"
        padding={spacing("4x")}
      >
        {title ? (
          <>
            <IconStore>
              <StoreSvg />
            </IconStore>
            <Typography
              variant="subtitle2"
              marginLeft={8}
              strong
              className="header-title"
            >
              {title}
            </Typography>
          </>
        ) : (
          <>
            {leadingIcon && (
              <Box
                data-testid="leadingIcon"
                position="absolute"
                left={spacing("6x")}
                onClick={() => leadingIcon.onClick()}
              >
                <Icon name={leadingIcon.iconName} color={leadingIcon.color} />
              </Box>
            )}
            <NuLogo variant="primary" size="small" />
          </>
        )}
      </Box>

      <div className="content">{children}</div>

      <Box
        display="flex"
        justifyContent="center"
        backgroundColor="#F0F1F5"
        padding={spacing("6x")}
        marginTop="auto"
        marginBottom={footerMarginBottom}
      >
        <LockSvg />
        <Typography variant="paragraph2" marginLeft={8} strong>
          {Text.FOOTER_LABEL}
        </Typography>
      </Box>
    </Container>
  );
};

export default Layout;
