import StatusTemplate from "../../templates/StatusTemplate";
import { ReactComponent as Timeout } from "../../assets/svg/nuis_light_timeout.svg";
import Text from "../../resources/text";

const Expired = () => {
  return (
    <StatusTemplate
      data-testid="expired"
      figure={<Timeout />}
      title={Text.STATUS_EXPIRED_TITLE}
      description={Text.STATUS_EXPIRED_DESCRIPTION}
    />
  );
};

export default Expired;
