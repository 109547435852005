import moment from "moment";

export function formatDateWithoutTime(date: string): string {
  return moment(date).format("DD/MM/YYYY");
}

export function formatInMonthAndYear(month: number, year: number): string {
  const formatedMonth = `${month > 9 ? month : `0${month}`}`;
  return `${formatedMonth}/${year}`;
}

export function formatDateNowWithTime(): string {
  return moment().format("DD/MM/YYYY HH:mm");
}

export function formatToBRL(value: string) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(parseFloat(value));
}

export function formatToPercentage(value: string) {
  return new Intl.NumberFormat("pt-BR", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(parseFloat(value) / 100);
}
