import { spacing, nuDSColor } from "@nubank/nuds-web/styles/themeUtils";
import styled from "styled-components";

export const Container = styled.div`
  .container {
    background-color: ${nuDSColor("black", "defaultT70")};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
      width: 90%;
      max-width: ${spacing(360)};

      &_header {
        width: 100%;
        position: relative;

        &_close {
          position: absolute;
          right: ${spacing("4x")};
          top: ${spacing("4x")};
          opacity: 85%;
          color: ${nuDSColor("black", "default")};
        }
      }

      &_content {
        padding: ${spacing("6x")};
        background-color: ${nuDSColor("white", "default")};
        margin-top: ${spacing("-1x")};

        &_title {
          font-size: ${spacing("6x")};
          font-weight: bold;
          margin-bottom: ${spacing("2x")};
        }

        &_description {
          font-size: ${spacing("4x")};
          line-height: ${spacing("6x")};

          li {
            text-indent: ${spacing("1x")};
          }
        }

        &_button {
          cursor: pointer;
        }
      }
    }

    .border_radius {
      &_top {
        border-top-left-radius: ${spacing("5x")};
        border-top-right-radius: ${spacing("5x")};
      }

      &_bottom {
        border-bottom-left-radius: ${spacing("5x")};
        border-bottom-right-radius: ${spacing("5x")};
      }

      &_all {
        border-radius: ${spacing("5x")};
        padding: ${spacing("40px", "6x")};
      }
    }

    strong {
      font-weight: bold;
    }
  }
`;
