import Box from "@nubank/nuds-web/components/Box/Box";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import { FC, useState } from "react";
import { Charging } from "../../adapters/charging.adapter";
import { ReactComponent as IconArrowRightSvg } from "../../assets/svg/icon_arrow_right.svg";
import { ReactComponent as IconCardSvg } from "../../assets/svg/icon_card.svg";
import { ReactComponent as IconNuLogoSvg } from "../../assets/svg/icon_nu_logo.svg";
import { ReactComponent as IconPixSvg } from "../../assets/svg/icon_pix.svg";
import { ReactComponent as UndoSvg } from "../../assets/svg/outlined_actions_undo.svg";
import ChargingButton from "../../components/ChargingButton";
import Layout from "../../components/Layout";
import LoadingIcon from "../../components/LoadingIcon";
import Popup from "../../components/Popup";
import Env from "../../config/Env";
import Text from "../../resources/text";
import AmplitudeService from "../../services/amplitude.service";
import Unavailable from "../Unavailable";
import { Container } from "./styles";

interface Home {
  charging: Charging;
  onPayByNupay: () => void;
  onPayByCard: () => void;
  onPayByPix: () => void;
}

const Home: FC<Home> = ({
  charging: {
    publicId,
    seller,
    amount,
    amountNupayWithPassOnFees,
    dueDate,
    installmentsOptions,
    paymentMethods,
    eventProps,
  },
  onPayByNupay,
  onPayByCard,
  onPayByPix,
}) => {
  const nupayMethodType = paymentMethods.find(
    (item) => item.method_type === "nupay"
  );

  const cardMethodType = paymentMethods.find(
    (item) => item.method_type === "card_transaction"
  );

  const pixMethodType = paymentMethods.find(
    (item) => item.method_type === "pix"
  );

  const withoutInterestNumber =
    installmentsOptions
      ?.filter((installment) => installment.fee === "0%")
      .at(-1)?.number || 0;

  const hasPassOnFeesEnabled =
    nupayMethodType?.pass_on_fees_enabled ||
    cardMethodType?.pass_on_fees_enabled;

  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false);
  const [showUnavailable, setShowUnavailable] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const nupaySubtitleButton = (
    allowedInstallments?: number,
    passOnFeesEnabled?: boolean
  ) => {
    if (passOnFeesEnabled) {
      return Text.HOME_CHARGING_BUTTON_01_SUBTITLE_03.replace(
        "{{value}}",
        `${amountNupayWithPassOnFees}`
      );
    }

    if (allowedInstallments && allowedInstallments > 1) {
      return Text.HOME_CHARGING_BUTTON_01_SUBTITLE_01.replace(
        "{{value}}",
        `${allowedInstallments}`
      );
    }

    if (allowedInstallments && allowedInstallments === 1) {
      return Text.HOME_CHARGING_BUTTON_02_SUBTITLE_02.replace(
        "{{value}}",
        "24"
      );
    }

    return Text.HOME_CHARGING_BUTTON_01_SUBTITLE_02;
  };

  const cardSubtitleButton = (passOnFeesEnabled?: boolean) => {
    if (installmentsOptions) {
      if (passOnFeesEnabled && installmentsOptions?.length > 1) {
        return Text.HOME_CHARGING_BUTTON_02_SUBTITLE_04.replace(
          "{{value}}",
          `${installmentsOptions[0].installment_amount}`
        );
      }

      if (passOnFeesEnabled && installmentsOptions?.length === 1) {
        return `${installmentsOptions[0].installment_amount}`;
      }

      if (withoutInterestNumber === 1 && installmentsOptions?.length === 1) {
        return Text.HOME_CHARGING_BUTTON_02_SUBTITLE_05.replace(
          "{{value}}",
          `${installmentsOptions.length}`
        );
      }

      if (withoutInterestNumber > 1) {
        return Text.HOME_CHARGING_BUTTON_02_SUBTITLE_01.replace(
          "{{value}}",
          `${withoutInterestNumber}`
        );
      }
      if (withoutInterestNumber === 1) {
        return Text.HOME_CHARGING_BUTTON_02_SUBTITLE_02.replace(
          "{{value}}",
          `${installmentsOptions.length}`
        );
      }
    }
    return Text.HOME_CHARGING_BUTTON_02_SUBTITLE_03;
  };

  const parseReasonRisk = (reason?: string) => {
    switch (reason) {
      case "cnp-soft-payments-amount-24h-cap-exceeded":
        return "pbl-cc-threshold-amount-24h";
      case "cnp-soft-payments-amount-30d-cap-exceeded":
        return "pbl-cc-threshold-amount-30d";
      case "cnp-soft-chargings-volume-24h-cap-exceeded":
        return "pbl-cc-threshold-links-24h";
      case "cnp-soft-chargings-volume-30d-cap-exceeded":
        return "pbl-cc-threshold-links-30d";
      default:
        return reason;
    }
  };

  if (showUnavailable) {
    AmplitudeService.screenViewed({
      entity_name: "pbl_unavailable",
      current_screen: "pbl_unavailable",
      ...eventProps,
    });
    return <Unavailable retry={() => window.location.reload()} />;
  }

  AmplitudeService.screenViewed({
    entity_name: "pbl_checkout",
    current_screen: "pbl_checkout",
    pass_on_fees: hasPassOnFeesEnabled,
    fingerprint_id: window.document.getElementById("fingerprint_input"),
    ...eventProps,
  });

  return (
    <Layout title={seller.name}>
      {showPopup && (
        <Popup
          show={{ showPopup, setShowPopup }}
          testid="cardUnavailablePopup"
          title={Text.POPUP_CC_THRESHOLD_TITLE}
          description={Text.POPUP_CC_THRESHOLD_DESCRIPTION}
          button={Text.POPUP_CC_THRESHOLD_BUTTON}
          buttonIcon={<UndoSvg fill="#fff" />}
          buttonAction={() => {
            AmplitudeService.buttonClicked({
              entity_name: "back",
              current_screen: parseReasonRisk(cardMethodType?.reason),
              screen_type: "pop_up",
              ...eventProps,
            });
            setShowPopup(false);
          }}
        />
      )}
      <Container data-testid="home">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderBottom="1px solid #f0f1f5"
          padding={spacing(0, "6x", "6x")}
        >
          <Box
            className="title"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gridRowGap={spacing("1x")}
            padding={spacing("20x", "6x")}
          >
            <Typography
              variant="subtitle2"
              strong
              data-testid="homeChargingLabel"
            >
              {Text.HOME_CHARGING_LABEL}
            </Typography>

            <Typography
              variant="heading1"
              data-testid="homeChargingAmount"
              className="text-amount"
            >
              {amount}
            </Typography>
          </Box>

          {nupayMethodType && (
            <ChargingButton
              leadingIcon={<IconNuLogoSvg />}
              trailingIcon={
                loadingIndicator ? (
                  <LoadingIcon primaryColor="primary.default" size="4x" />
                ) : (
                  <IconArrowRightSvg fill="#820AD1" />
                )
              }
              title={Text.HOME_CHARGING_BUTTON_01}
              subtitle={nupaySubtitleButton(
                nupayMethodType.allowed_installments,
                nupayMethodType.pass_on_fees_enabled
              )}
              testid="payByNupayButton"
              onClick={() => {
                AmplitudeService.buttonClicked({
                  entity_name: "pay_with_nubank",
                  current_screen: "pbl_checkout",
                  max_installments: nupayMethodType.allowed_installments,
                  pass_on_fees: nupayMethodType.pass_on_fees_enabled,
                  href: `${Env.CHECKOUT_URL}/nupay-payment-intent/${publicId}`,
                  ...eventProps,
                });
                if (!nupayMethodType?.available) {
                  return setShowUnavailable(true);
                }
                setLoadingIndicator(true);
                setTimeout(() => setLoadingIndicator(false), 2000);
                return onPayByNupay();
              }}
            />
          )}

          {cardMethodType && (
            <ChargingButton
              leadingIcon={<IconCardSvg />}
              trailingIcon={<IconArrowRightSvg fill="#820AD1" />}
              title={Text.HOME_CHARGING_BUTTON_02}
              subtitle={cardSubtitleButton(cardMethodType.pass_on_fees_enabled)}
              testid="payByCardButton"
              onClick={() => {
                AmplitudeService.buttonClicked({
                  entity_name: "pay_with_cc",
                  current_screen: "pbl_checkout",
                  max_installments: withoutInterestNumber,
                  pass_on_fees: cardMethodType.pass_on_fees_enabled,
                  ...eventProps,
                });
                if (!cardMethodType?.available) {
                  return setShowUnavailable(true);
                }
                if (!cardMethodType?.enabled && cardMethodType?.reason) {
                  AmplitudeService.screenViewed({
                    entity_name: parseReasonRisk(cardMethodType.reason),
                    current_screen: "pbl_checkout",
                    screen_type: "pop_up",
                    ...eventProps,
                  });
                  return setShowPopup(true);
                }
                return onPayByCard();
              }}
            />
          )}

          {pixMethodType && (
            <ChargingButton
              leadingIcon={<IconPixSvg />}
              trailingIcon={<IconArrowRightSvg fill="#820AD1" />}
              title={Text.HOME_CHARGING_BUTTON_03}
              subtitle={cardMethodType?.pass_on_fees_enabled ? amount : ""}
              testid="payByPixButton"
              onClick={() => {
                AmplitudeService.buttonClicked({
                  entity_name: "pay_with_pix",
                  current_screen: "pbl_checkout",
                  ...eventProps,
                });
                if (!pixMethodType?.available) {
                  return setShowUnavailable(true);
                }
                return onPayByPix();
              }}
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          borderBottom="1px solid #f0f1f5"
          padding={spacing("6x")}
          className="box-charging-details"
        >
          <Box>
            <Typography
              variant="paragraph2"
              strong
              data-testid="homeChargingDueDateLabel"
            >
              {Text.HOME_CHARGING_DUE_DATE_LABEL}
            </Typography>

            <Typography variant="paragraph2" data-testid="homeChargingDueDate">
              {dueDate}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="paragraph2"
              strong
              data-testid="homeChargingCnpjLabel"
            >
              {Text.HOME_CHARGING_CNPJ_LABEL}
            </Typography>

            <Typography variant="paragraph2" data-testid="homeChargingCnpj">
              {seller.taxId}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default Home;
