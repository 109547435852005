/* eslint-disable camelcase */
import { FC, useEffect, useRef } from "react";
import Box from "@nubank/nuds-web/components/Box/Box";
import RadioButtonGroup from "@nubank/nuds-web/components/RadioButtonGroup/RadioButtonGroup";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import { Container } from "./styles";
import Text from "../../resources/text";
import { InstallmentOption } from "../../services/checkout.service";
import AmplitudeService from "../../services/amplitude.service";

interface InstallmentsModal {
  visible: {
    modalVisible: boolean;
    setModalVisible: (modalVisible: boolean) => void;
  };
  installmentOptions: InstallmentOption[];
  setInstallmentsNumber: (installmentsNumber: number) => void;
  eventProps: Record<string, string>;
}

const clickOutside = (ref, modalVisible, setModalVisible) => {
  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (modalVisible && !ref.current.contains(target)) {
        setModalVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, modalVisible]);
};

const InstallmentsModal: FC<InstallmentsModal> = ({
  visible: { modalVisible, setModalVisible },
  installmentOptions,
  setInstallmentsNumber,
  eventProps,
}) => {
  const wrapperRef = useRef(null);
  clickOutside(wrapperRef, modalVisible, setModalVisible);
  document.body.style.overflow = `${modalVisible ? "hidden" : "auto"}`;

  return (
    <Container>
      <Box
        id="bottom-modal"
        className={`bottom-modal ${modalVisible && "show"}`}
        display="flex"
        ref={wrapperRef}
      >
        <RadioButtonGroup
          label={
            <Box className="modal-header">
              <Box className="top-bar" />
              <Typography variant="heading3" color="black.light">
                {Text.PAYMENT_MODAL_TITLE}
              </Typography>
            </Box>
          }
          id="installmentsNumber"
          name="installmentsNumber"
          styleType="default"
          options={installmentOptions.map(
            ({ number, installment_amount, total_amount, fee }) => {
              return {
                value: `${number}`,
                label: (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {number === 1 ? (
                      <Typography variant="subtitle2" strong>
                        {number}x {installment_amount}{" "}
                        {Text.PAYMENT_INSTALLMENT_SINGLE}
                      </Typography>
                    ) : (
                      <>
                        <Box>
                          <Typography variant="subtitle2" strong>
                            {number}x {installment_amount}
                          </Typography>
                          <Typography
                            variant="paragraph2"
                            className="secondary-color"
                          >
                            Total {total_amount}
                          </Typography>
                        </Box>
                        <Box
                          borderRadius={spacing("1x")}
                          color={fee === "0%" ? "#0C7A3A" : "#000000"}
                          backgroundColor={fee === "0%" ? "#DDF5E5" : "#F0F1F5"}
                        >
                          <Typography variant="overline" padding="2px 4px">
                            {fee === "0%" ? Text.PAYMENT_MODAL_BADGE : fee}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                ),
              };
            }
          )}
          onChange={({ target: { value } }) => {
            AmplitudeService.buttonClicked({
              entity_name: "select_installments",
              current_screen: "cc_installments_modal",
              option_selected: value,
              ...eventProps,
              installment_mode:
                installmentOptions.find(
                  (option) => option.number === Number(value)
                )?.fee === "0%"
                  ? "PSJ"
                  : "PCJ",
            });
            setInstallmentsNumber(Number(value));
            setModalVisible(false);
          }}
        />
      </Box>
      <Box className={`${modalVisible && "overlay"}`} />
    </Container>
  );
};

export default InstallmentsModal;
