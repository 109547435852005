import Text from "../resources/text"
import { ReactComponent as InsufficientFunds } from "../assets/svg/nuis_light_cancel_purchase.svg";
import { ReactComponent as TryAgainLater } from "../assets/svg/nuis_light_try_again_later.svg";
import { ReactComponent as UpdatedInformationNeeded } from "../assets/svg/nuis_light_missing_or_incorrect_information.svg";
import { ReactComponent as ContactCentral } from "../assets/svg/nuis_light_confirmation_by_e-mail.svg";
import { ReactComponent as FraudTransaction } from "../assets/svg/nuis_light_card_inactivated.svg";
import { ReactComponent as LockedCard } from "../assets/svg/nuis_light_card_lock.svg";

interface PaymentErrorScreenParams {
    title: string,
    description: string,
    testId: string,
    figure: any
}

export function paymentErrorScreen(failedReason: string): PaymentErrorScreenParams {

    const params: PaymentErrorScreenParams = {
        testId: "try_again_later_error",
        title: Text.STATUS_TRY_AGAIN_LATER_TITLE,
        description: Text.STATUS_TRY_AGAIN_LATER_DESCRIPTION,
        figure: TryAgainLater ,
    };

    switch(failedReason) {
        case "insufficient_funds": {
            params.testId = "insufficient_funds_error";
            params.title = Text.STATUS_INSUFFICIENT_FUNDS_TITLE;
            params.description = Text.STATUS_INSUFFICIENT_FUNDS_DESCRIPTION;
            params.figure = InsufficientFunds;
            break;
        }
        case "updated_information_needed": {
            params.testId = "updated_information_needed_error";
            params.title = Text.STATUS_UPDATED_INFORMATION_NEEDED_TITLE;
            params.description = Text.STATUS_UPDATED_INFORMATION_NEEDED_DESCRIPTION;
            params.figure = UpdatedInformationNeeded;
            break;
        }
        case "contact_central": {
            params.testId = "contact_central_error";
            params.title = Text.STATUS_CONTACT_CENTRAL_TITLE;
            params.description = Text.STATUS_CONTACT_CENTRAL_DESCRIPTION;
            params.figure = ContactCentral;
            break;
        }
        case "fraud_transaction": {
            params.testId = "fraud_transaction_error";
            params.title = Text.STATUS_FRAUD_TRANSACTION_TITLE;
            params.description = Text.STATUS_FRAUD_TRANSACTION_DESCRIPTION;
            params.figure = FraudTransaction;
            break;
        }
        case "locked_card": {
            params.testId = "locked_card_error";
            params.title = Text.STATUS_LOCKED_CARD_TITLE;
            params.description = Text.STATUS_LOCKED_CARD_DESCRIPTION;
            params.figure = LockedCard;
            break;
        }
        default: {
            break;
        }
    }

    return params;
}