import { FC, ReactElement } from "react";
import StatusTemplate from "../../templates/StatusTemplate";
import Text from "../../resources/text";


export interface PaymentError {
  figure: ReactElement;
  title: string;
  description: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  testId: string;
}

const PaymentError: FC<PaymentError> = ({ figure, title, description, primaryButtonAction, secondaryButtonAction, testId }) => {
  return (
    <StatusTemplate
      data-testid={testId}
      figure={figure}
      title={title}
      description={description}
      mainButton={{
        text: Text.STATUS_PAYMENT_ERROR_BACK_HOME,
        testId: "back-home-button",
        iconProps: { name: "undo" },
        action: primaryButtonAction,
      }}
      secondaryButton={{
        text: Text.STATUS_NUPAY,
        testId: "pay-with-nupay-button",
        iconProps: { name: "arrow-up-right" },
        action: secondaryButtonAction,
      }}
    />
  );
};

export default PaymentError;