import { FC, ReactElement, useState } from "react";
import Box from "@nubank/nuds-web/components/Box/Box";
import Button from "@nubank/nuds-web/components/Button/Button";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import { Container } from "./styles";
import LoadingIcon from "../LoadingIcon";

interface Popup {
  show: {
    showPopup: boolean;
    setShowPopup: (showPopup: boolean) => void;
  };
  testid?: string;
  popupBanner?: ReactElement;
  title: string;
  description: string;
  button: string;
  buttonIcon: ReactElement;
  buttonAction: () => void;
}

const Popup: FC<Popup> = ({
  show: { showPopup, setShowPopup },
  testid,
  popupBanner,
  title,
  description,
  button,
  buttonIcon,
  buttonAction,
}) => {
  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false);
  return (
    <Container data-testid={testid}>
      {showPopup ? (
        <Box className="container">
          <Box className="popup">
            {popupBanner && (
              <Box className="popup_header">
                <Button
                  className="popup_header_close"
                  iconProps={{ name: "x" }}
                  styleVariant="white"
                  onClick={() => setShowPopup(false)}
                />
                {popupBanner}
              </Box>
            )}
            <Box
              className={`popup_content ${
                popupBanner ? "border_radius_bottom" : "border_radius_all"
              }`}
            >
              <Typography className="popup_content_title">{title}</Typography>
              <Typography
                className="popup_content_description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <Box
                className="popup_content_button"
                display="flex"
                alignItems="center"
                backgroundColor="primary.default"
                color="white.default"
                borderRadius={spacing("8x")}
                padding={spacing("4x", "5x", "4x", "1x")}
                onClick={() => {
                  setLoadingIndicator(true);
                  buttonAction();
                  setTimeout(() => setLoadingIndicator(false), 2000);
                }}
                marginTop={spacing("6x")}
              >
                <Box
                  flexGrow="1"
                  gridRowGap={spacing("1x")}
                  paddingLeft={spacing("4x")}
                >
                  <Typography variant="subtitle2" strong>
                    {button}
                  </Typography>
                </Box>
                <Box>
                  {loadingIndicator ? (
                    <LoadingIcon
                      primaryColor="white.default"
                      secondaryColor="#ab4bea"
                      size="4x"
                    />
                  ) : (
                    buttonIcon
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Container>
  );
};

export default Popup;
