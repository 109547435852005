import { extractPanWithoutSpaces, extractTypeCard } from "./extract";

export function maskPan(pan: string) {
  const value = pan.replace(/ /g, "").replace(/[^\d]/g, "");

  let parts = "";
  for (let i = 0; i <= value.length / 4 && i < 4; i += 1) {
    parts += `${value.slice(i * 4, (i + 1) * 4)} `;
  }

  return parts.trim();
}

export function maskExpirationDate(expirationDate: string) {
  const value = expirationDate.replace(/ /g, "").replace(/\//g, "");
  const part1 = value.slice(0, 2);
  const part2 = value.slice(2, 4);

  if (value.length > 2) {
    return `${part1}/${part2}`.trim();
  }

  return value;
}

function maskTaxCPF(value: string) {
  return value
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
}

function maskTaxCNPJ(value: string) {
  return value
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
}

export function maskTaxId(taxId: string) {
  const value = taxId.replace(/\D/g, "");

  if (value.length <= 11) {
    return maskTaxCPF(value);
  }

  return maskTaxCNPJ(value);
}

export function maskPhone(phone: string) {
  return phone
    .replace(/^\+/, "")
    .replace(/^(55)/, "")
    .replace(/[^\d]/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");
}

export function maskPanDetails(
  pan: string,
  type: "4_last" | "6_first_and_4_last"
) {
  const value = extractPanWithoutSpaces(pan);
  if (type === "4_last") {
    return value.replace(/^[\d-\s]+(?=\d{4})/, "**** ");
  }

  return `${value.slice(0, 6)}${"*".repeat(value.length - 10)}${value.slice(
    -4
  )}`;
}

function convertUpperCaseTypeCard(typeCard: string) {
  return typeCard.charAt(0).toUpperCase() + typeCard.substring(1);
}

export function maskPanWithBrandName(pan: string) {
  const value = maskPanDetails(pan, "4_last");
  const typeCard = extractTypeCard(pan);

  return `${value} ${convertUpperCaseTypeCard(typeCard)}`;
}

export function maskCep(cep: string) {
  return cep.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2");
}
