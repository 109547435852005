import validator from "card-validator";
import { validator as validatorCpfCnpj } from "cpf-cnpj-validator";
import Joi from "joi";
import {
  extractFirstAndLastName,
  extractMonthAndYear,
  extractPhoneWithoutCharacter,
  extractTaxIdCharacter,
} from "./extract";

const nuBrBins = {
  mcs: "5122 72",
  mcg: "5502 09",
  mcg2: "2307 21",
  mpl: "5162 92",
  mpl2: "5253 61",
  mlc: "5226 26",
  mbk: "5200 48",
};

export function isValidPan(pan: string) {
  return validator.number(pan).isValid;
}

export function isNuBrPan(pan: string, bins = Object.values(nuBrBins)) {
  return bins.includes(pan.slice(0, 7));
}

export function isTypeCard(pan: string, types = ["mastercard", "visa", "elo"]) {
  const result = validator.number(pan);
  return types.includes(result?.card?.type!);
}

export function isValidExpirationDate(
  expirationDate: string,
  today = new Date()
) {
  const { month, year } = extractMonthAndYear(expirationDate);
  return (
    (year === today.getFullYear() && month > today.getMonth()) ||
    year > today.getFullYear()
  );
}

export function isValidMonthExpirationDate(expirationDate: string) {
  const { month } = extractMonthAndYear(expirationDate);
  return month > 0 && month <= 12;
}

export function isValidCardCVV(cvv: string) {
  return cvv.length === 3;
}

export function isValidFullName(fullName: string) {
  const { firstName, lastName } = extractFirstAndLastName(fullName);

  return firstName.length >= 2 && lastName && lastName.length >= 1;
}

export function isValidFullNameCharacter(fullName: string) {
  return fullName.match(/^(([A-zÀ-ú]{1,})\s*){2,}$/);
}

export function isValidTaxId(taxId: string) {
  const joi = Joi.extend(validatorCpfCnpj);
  const cnpjSchema = joi.document().cnpj();
  const cpfSchema = joi.document().cpf();
  const value = extractTaxIdCharacter(taxId);

  if (value.length <= 11) {
    return !cpfSchema.validate(value).error;
  }

  return !cnpjSchema.validate(value).error;
}

export function isValidPhone(phone: string) {
  const value = extractPhoneWithoutCharacter(phone);
  const initialDigit = value.substring(2, 3);
  const ddd = value.substring(0, 2);
  const validDDD = [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
    37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
    65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  ];

  if (
    value.length < 10 ||
    (value.length === 11 && parseInt(initialDigit, 10) !== 9) ||
    validDDD.indexOf(parseInt(ddd, 10)) === -1
  ) {
    return false;
  }

  return true;
}
export function isValidCep(cep: string) {
  return /^[0-9]{5}-[0-9]{3}$/.test(cep);
}
