import { FC } from "react";
import StatusTemplate from "../../templates/StatusTemplate";
import { ReactComponent as TryAgainLater } from "../../assets/svg/nuis_light_try_again_later.svg";
import Text from "../../resources/text";

export interface Unavailable {
  retry: () => void;
}
const Unavailable: FC<Unavailable> = ({ retry }) => {
  return (
    <StatusTemplate
      data-testid="unavailable"
      figure={<TryAgainLater />}
      title={Text.STATUS_UNAVAILABLE_TITLE}
      description={Text.STATUS_UNAVAILABLE_DESCRIPTION}
      mainButton={{
        text: Text.STATUS_UNAVAILABLE_BUTTON,
        testId: "retry-button",
        action: retry,
      }}
      centralized
    />
  );
};

export default Unavailable;
