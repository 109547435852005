import { useState } from "react";
import { v4 as uuid } from "uuid";
import { Charging } from "../adapters/charging.adapter";
import Env from "../config/Env";
import {
  extractMonthAndYear,
  extractPanWithoutSpaces,
  extractPhoneWithoutCharacter,
} from "../helpers/extract";
import { callThreeDSMethod, encryptedPanJWE } from "../helpers/threeDS";
import CheckoutService, {
  CheckoutStatus,
  GetByPublicIdStatus,
} from "../services/checkout.service";
import { buildBrowserData } from "../helpers/browserData";
import { CardRequest } from "../services/tokezation.service";

export interface CheckoutRequest {
  pan: string;
  brand: string;
  holderName: string;
  expirationDate: string;
  cvv: string;
  fullName: string;
  email: string;
  taxId: string;
  phone: string;
  fingerprintId: string;
  installmentsNumber: number;
  postcode: string;
}

export interface ChallengeDetails {
  challengeUrl?: string;
  creq?: string
  failed_reason?: string;
}

export const useCharging = () => {
  const [status, setStatus] = useState<GetByPublicIdStatus>();
  const [failedReason, setFailedReason] = useState<string>();
  const [checkoutStatus, setCheckoutStatus] = useState<CheckoutStatus>();
  const [challengeDetails, setChallengeDetails] = useState<ChallengeDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  const [charging, setCharging] = useState<Charging>();
  const [referenceId, setReferenceId] = useState(uuid());
  const [threeDSTransactionId, setThreeDSTransactionId] = useState<string>();

  async function loadCharging(publicId: string) {
    setLoading(true);
    try {
      const response = await CheckoutService.chargingByPublicId(publicId);

      setCharging(response);
    } catch (error) {
      setStatus(error as GetByPublicIdStatus);
    }
    setLoading(false);
  }

  async function silentReloadCharging(publicId: string) {
    try {
      const response = await CheckoutService.chargingByPublicId(publicId);
      setCharging(response);
    } catch (error) {
      setStatus(error as GetByPublicIdStatus);
    }
  }

  function refreshReferenceId() {
    setReferenceId(uuid());
  }

  function reload() {
    window.location.reload();
  }

  async function checkout(
    publicId: string,
    {
      pan,
      brand,
      holderName,
      expirationDate,
      cvv,
      fullName,
      email,
      taxId,
      phone,
      fingerprintId,
      installmentsNumber,
      postcode,
    }: CheckoutRequest
  ) {
    const { month, year } = extractMonthAndYear(expirationDate);
    const codBrPhone = "55";
    const panWithoutSpaces = extractPanWithoutSpaces(pan);
    const browserData = buildBrowserData();
    let threeDsServerTransId: string | undefined;

    const cardRequest: CardRequest = {
      card_number: panWithoutSpaces,
      card_brand: brand,
      holder_name: holderName,
      expiration_month: month,
      expiration_year: year,
      security_code: cvv,
    };

    const buyerRequest = {
      name: fullName,
      email,
      tax_id: taxId,
      phone: `${codBrPhone}${extractPhoneWithoutCharacter(phone)}`,
      fingerprint_id: fingerprintId,
      installments_number: installmentsNumber,
      address: { postcode },
    };

    const encryptedPan = await encryptedPanJWE(
      panWithoutSpaces,
      Env.PAN_ENCRYPTION_PUBLIC_KEY,
      Env.PAN_ENCRYPTION_FINGERPRINT
    );
    cardRequest.encrypted_pan = encryptedPan;

    if (charging?.threeDsEnabled) {
      const supportedVersion = await CheckoutService.getSupportedVersion(
        publicId,
        encryptedPan
      );

      if (!supportedVersion || !supportedVersion.three_ds_server_trans_id) {
        setCheckoutStatus(CheckoutStatus.error);
        refreshReferenceId();
        return;
      }

      if (supportedVersion.fingerprint_url) {
        threeDsServerTransId = supportedVersion.three_ds_server_trans_id;

        setThreeDSTransactionId(threeDsServerTransId);

        callThreeDSMethod(
          supportedVersion.fingerprint_url,
          supportedVersion.three_ds_server_trans_id,
          "https://nubank.com.br"
        );
      }
    }

    const checkoutResult = await CheckoutService.checkout(
      publicId,
      referenceId,
      cardRequest,
      buyerRequest,
      browserData,
      threeDsServerTransId
    );

    setCheckoutStatus(checkoutResult.status);

    if (checkoutResult.details?.failed_reason) {
      setFailedReason(checkoutResult.details.failed_reason)
    }

    if (checkoutResult.details && checkoutResult.details.creq && checkoutResult.details.challengeUrl) {
      setChallengeDetails(checkoutResult.details);
    }

    if (checkoutResult.status === CheckoutStatus.denied) {
      refreshReferenceId();
    }
  }

  async function getTransactionAuthenticationResult() {
    if (charging && threeDSTransactionId) {
      return CheckoutService.getTransactionAuthentication(
        charging.publicId,
        threeDSTransactionId
      );
    }

    return null;
  }

  async function nupay(publicId: string) {
    const response = await CheckoutService.createPaymentIntent(
      publicId,
      referenceId
    );

    if (response === CheckoutStatus.error) {
      setStatus(GetByPublicIdStatus.unknown);
      loadCharging(publicId);
    }
  }

  return {
    status,
    setFailedReason,
    failedReason,
    loading,
    charging,
    loadCharging,
    checkout,
    nupay,
    checkoutStatus,
    challengeDetails,
    reload,
    setCheckoutStatus,
    silentReloadCharging,
    getTransactionAuthenticationResult,
  };
};
