import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;

  .content {
    display: flex;
    flex-grow: 1;
  }

  iframe {
    height: 100%;
  }
`;
