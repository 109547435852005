import { FC, useEffect, useRef, useState } from "react";
import Box from "@nubank/nuds-web/components/Box/Box";
import Layout from "../../components/Layout";
import { Container } from "./styles";
import Env from "../../config/Env";
import { CheckoutStatus, TransactionAuthenticationResponse } from "../../services/checkout.service";

export interface Challenged {
  challengeUrl: string;
  creq: string;
  getTransactionAuthenticationResult: () => Promise<TransactionAuthenticationResponse | null>;
  setCheckoutStatus: React.Dispatch<
    React.SetStateAction<CheckoutStatus | undefined>
  >;
  setFailedReason: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const Challenged: FC<Challenged> = ({
  challengeUrl,
  creq,
  setCheckoutStatus,
  getTransactionAuthenticationResult,
  setFailedReason,
}) => {
  const challengeForm = useRef<HTMLFormElement>(null);

  const [pollingInterval, setPollingInterval] = useState<number>(
    Env.THREE_DS_POOLING_INTERVAL
  );

  const [errorCount, setErrorCount] = useState<number>(0);

  useEffect(() => {
    challengeForm?.current?.submit();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getTransactionAuthenticationResult().then((response) => {
        if (response && response.status === CheckoutStatus.error && errorCount < Env.THREE_DS_POOLING_MAX_ERRORS-1) {
          setErrorCount(errorCount + 1);
          setPollingInterval(pollingInterval + pollingInterval * 0.2);
        } else if (response) {
          if (response.details?.failed_reason && response.status === CheckoutStatus.denied) {
            setFailedReason(response.details.failed_reason);
          }
          setCheckoutStatus(response.status);
          setPollingInterval(pollingInterval + pollingInterval * 0.01);
        }
      });
    }, pollingInterval);

    return () => clearTimeout(timeoutId);
  }, [pollingInterval, errorCount]);

  return (
    <Container>
      <Layout>
        <Box
          display="flex"
          flexGrow="1"
          flexDirection="column"
          borderTop="1px solid #f0f1f5"
        >
          <iframe title="challengeIframe" name="challengeIframe" />

          <form
            method="POST"
            action={challengeUrl}
            target="challengeIframe"
            ref={challengeForm}
          >
            <input type="hidden" name="creq" value={creq} />
          </form>
        </Box>
      </Layout>
    </Container>
  );
};

export default Challenged;
