import { spacing, nuDSColor } from "@nubank/nuds-web/styles/themeUtils";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  label > div > span {
    font-size: 18px;
  }

  .seller-name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: ${spacing("4x")};
  }

  .address-caption {
    position: absolute;
    margin-top: ${spacing("-8x")};
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input::placeholder {
    color: ${nuDSColor("black", "defaultT20")};
  }

  #form-bottom-box {
    position: relative;
    width: 100%;
  }

  #button-bottom-box {
    display: flex;
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    justify-content: center;

    > div {
      max-width: 540px;
      width: 100%;
      background-color: ${nuDSColor("white", "default")};
      padding: ${spacing("4x", "6x")};
      button {
        width: 100%;
        margin: 0px;
      }
    }
  }

  .link-button {
    position: relative;
    cursor: pointer;
    margin-top: ${spacing("-4x")};
    margin-bottom: ${spacing("4x")};
    padding: ${spacing("4x")};
    border-radius: ${spacing("2x")};
  }

  .link-button:before {
    content: "";
    position: absolute;
    width: ${spacing("5x")};
    height: ${spacing("5x")};
    top: ${spacing("-6px")};
    background: ${nuDSColor("primary.default")};
    -webkit-transform: rotate(45deg);
  }
`;
