import { FC } from "react";
import StatusTemplate from "../../templates/StatusTemplate";
import { ReactComponent as TryAgainNow } from "../../assets/svg/nuis_light_try_again_now.svg";
import Text from "../../resources/text";

export interface Denied {
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
}

const Denied: FC<Denied> = ({ primaryButtonAction, secondaryButtonAction }) => {
  return (
    <StatusTemplate
      data-testid="denied"
      figure={<TryAgainNow />}
      title={Text.STATUS_DENIED_TITLE}
      description={Text.STATUS_DENIED_DESCRIPTION}
      mainButton={{
        text: Text.STATUS_BACK_HOME,
        testId: "back-home-button",
        iconProps: { name: "undo" },
        action: primaryButtonAction,
      }}
      secondaryButton={{
        text: Text.STATUS_NUPAY,
        testId: "pay-with-nupay-button",
        iconProps: { name: "arrow-up-right" },
        action: secondaryButtonAction,
      }}
    />
  );
};

export default Denied;
