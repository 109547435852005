import { spacing, nuDSColor } from "@nubank/nuds-web/styles/themeUtils";
import styled from "styled-components";

export const Container = styled.div`
  padding: ${spacing("6x")};

  figure {
    margin: ${spacing("18x", "0x", "2x")};
  }
  .centralize {
    text-align: center;
  }
  .description {
    margin: ${spacing("6x", "0x", "12x")};
    span {
      color: ${nuDSColor("primary", "default")};
    }
  }
  .box-charging-details div {
    display: flex;
    justify-content: space-between;
  }

  .box-charging-details p {
    margin-bottom: ${spacing("4x")};
  }

  .values-details {
    text-align: right;
    padding-left: ${spacing("12x")};
  }

  .main-button {
    background-color: ${nuDSColor("primary", "default")};
    color: ${nuDSColor("white")};
    border-radius: ${spacing("6x")};
    height: ${spacing("12x")};
    padding: ${spacing("4x")};
    cursor: pointer;
    font-weight: bold;
    margin-bottom: ${spacing("4x")};
  }

  .secondary-button {
    background-color: ${nuDSColor("white", "dark")};
  }
`;
