import { BrowserData } from "../services/checkout.service";

export const timezone = (): string => {
  const tz = new Date().getTimezoneOffset()/-60
  return tz.toString();
};

// due axios lib the accept header is always application/json
export const acceptHeader = "application/json";
export const javascriptEnabled = "true";

export const isJavaEnabled = () => {
  let java: string;

  try {
    java = `${navigator.javaEnabled()}`;
  } catch (error) {
    java = "false";
  }
  return java;
};

export const buildBrowserData = (): BrowserData => {
  return {
    browser_accept_header: acceptHeader,
    browser_user_agent: `${navigator.userAgent}`,
    browser_java_enabled: isJavaEnabled(),
    browser_javascript_enabled: javascriptEnabled,
    browser_language: `${navigator.language}`,
    browser_color_depth: `${window.screen.colorDepth}`,
    browser_screen_height: `${window.screen.height}`,
    browser_screen_width: `${window.screen.width}`,
    browser_tz: timezone(),
  };
};
