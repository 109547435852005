import { spacing, nuDSColor } from "@nubank/nuds-web/styles/themeUtils";
import styled from "styled-components";

export const Container = styled.div`
  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
  }

  .bottom-modal {
    width: 100%;
    max-height: 0;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    transition: max-height 0.8s ease-in;

    > div {
      background-color: ${nuDSColor("white", "default")};
      border-radius: 16px 16px 0px 0px;
      width: 100%;
      padding-bottom: ${spacing("16x")};
      max-width: 520px;
      overflow-y: auto;
      grid-row-gap: 3.5em;

      .modal-header {
        background-color: ${nuDSColor("white", "default")};
        border-radius: 16px 16px 0px 0px;
        width: 100%;
        max-width: 520px;
        position: fixed;
        margin-top: ${spacing("-4x")};
        padding: ${spacing("6x")};
        z-index: 1;

        .top-bar {
          background: #d8dae0;
          width: ${spacing("8x")};
          height: ${spacing("1x")};
          margin: 0 calc(50% - ${spacing("4x")}) ${spacing("6x")};
        }
      }

      #label-installmentsNumber-1 {
        margin-top: ${spacing("12x")};
      }

      label {
        width: calc(100% - ${spacing("12x")});
        margin: ${spacing(0, "6x")};
        align-items: center;

        :before {
          transform: scale(1.2);
          border-width: 1.2px;
        }

        > span {
          width: 100%;
        }
      }
    }
  }

  .bottom-modal.show {
    max-height: 75vh;
    transition: max-height 0.8s ease-out;
  }

  .secondary-color {
    color: #727683;
  }
`;
