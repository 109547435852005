import StatusTemplate from "../../templates/StatusTemplate";
import { ReactComponent as ResultsNotFound } from "../../assets/svg/nuis_light_results_not_found.svg";
import Text from "../../resources/text";

const NotFound = () => {
  return (
    <StatusTemplate
      data-testid="notFound"
      figure={<ResultsNotFound />}
      title={Text.STATUS_NOT_FOUND_TITLE}
      description={Text.STATUS_NOT_FOUND_DESCRIPTION}
    />
  );
};

export default NotFound;
