import { FC } from "react";
import StatusTemplate from "../../templates/StatusTemplate";
import { ReactComponent as TryAgainNow } from "../../assets/svg/nuis_light_try_again_now.svg";
import Text from "../../resources/text";

export interface Error {
  retry: () => void;
}
const Error: FC<Error> = ({ retry }) => {
  return (
    <StatusTemplate
      data-testid="error"
      figure={<TryAgainNow />}
      title={Text.STATUS_ERROR_TITLE}
      description={Text.STATUS_ERROR_DESCRIPTION}
      mainButton={{
        text: Text.STATUS_ERROR_TEXT_BUTTON,
        testId: "retry-button",
        iconProps: { name: "arrow-right" },
        action: retry,
      }}
    />
  );
};

export default Error;
