import { spacing, nuDSColor } from "@nubank/nuds-web/styles/themeUtils";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${spacing(540)};

  height: 100%;
  min-height: 100vh;
  background-color: ${nuDSColor("white", "default")};

  header {
    h1 {
      margin-left: ${spacing("2x")};
      font-weight: 600;
      font-size: 14px;
    }
  }

  .header-title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .body-title {
    font-size: ${spacing(30)};
  }

  .description {
    margin-bottom: ${spacing("6x")};
  }
`;

export const IconStore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${spacing("8x")};
  height: ${spacing("8x")};
  background-color: ${nuDSColor("white", "dark")};
  border-radius: 50%;
`;
