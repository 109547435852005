import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import Box from "@nubank/nuds-web/components/Box/Box";

import Layout from "../components/Layout";
import Error from "./Error";
import Expired from "./Expired";
import Home from "./Home";
import NotFound from "./NotFound";
import Paid from "./Paid";
import CardPayment from "./CardPayment";
import PixPayment from "./PixPayment";

import { Container } from "./Home/styles";
import { useCharging } from "../hooks/useCharging";
import { GetByPublicIdStatus } from "../services/checkout.service";
import AmplitudeService from "../services/amplitude.service";

const Pages = () => {
  const { publicId } = useParams<{ publicId: string }>();
  const [showCheckout, setShowCheckout] = useState(false);
  const [showPixPayment, setShowPixPayment] = useState(false);

  const {
    loading,
    status,
    setFailedReason,
    failedReason,
    loadCharging,
    charging,
    checkout,
    nupay,
    reload,
    checkoutStatus,
    challengeDetails,
    silentReloadCharging,
    setCheckoutStatus,
    getTransactionAuthenticationResult,
  } = useCharging();

  useEffect(() => {
    loadCharging(publicId);
  }, []);

  if (status === GetByPublicIdStatus.paid) {
    return <Paid />;
  }
  if (status === GetByPublicIdStatus.expired) {
    return <Expired />;
  }
  if (status === GetByPublicIdStatus.notFound) {
    return <NotFound />;
  }
  if (status === GetByPublicIdStatus.unknown) {
    return <Error retry={() => window.location.reload()} />;
  }

  if (!loading && charging) {
    const { eventProps } = charging;

    if (showPixPayment) {
      return (
        <PixPayment
          charging={charging}
          onClose={() => setShowPixPayment(false)}
          reloadCharge={() => silentReloadCharging(publicId)}
        />
      );
    }

    if (showCheckout) {
      AmplitudeService.screenViewed({
        fingerprint_id: window.document.getElementById("fingerprint_input"),
        entity_name: "cc_checkout",
        current_screen: "cc_checkout",
        ...eventProps,
      });

      return (
        <CardPayment
          getTransactionAuthenticationResult={
            getTransactionAuthenticationResult
          }
          status={checkoutStatus}
          setFailedReason={setFailedReason}
          failedReason={failedReason}
          challengeDetails={challengeDetails}
          charging={charging}
          reload={reload}
          setCheckoutStatus={setCheckoutStatus}
          onCheckout={async (request) => checkout(publicId, request)}
          onPayByNupay={() => nupay(publicId)}
          setShowCheckout={setShowCheckout}
        />
      );
    }

    return (
      <Home
        charging={charging}
        onPayByNupay={() => nupay(publicId)}
        onPayByCard={() => setShowCheckout(true)}
        onPayByPix={() => setShowPixPayment(true)}
      />
    );
  }

  return (
    <Layout>
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            data-testid="loading"
            className="loading"
            border="4px solid #fff"
            marginTop={spacing(240)}
            borderTopColor="primary.default"
            borderRightColor="primary.default"
            width={spacing("12x")}
            height={spacing("12x")}
            borderRadius={50}
          />
        </Box>
      </Container>
    </Layout>
  );
};
export default Pages;
