import { FC, ReactElement } from "react";
import Button from "@nubank/nuds-web/components/Button/Button";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import Box from "@nubank/nuds-web/components/Box/Box";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import Layout from "../../components/Layout";
import { Container } from "./styles";
import { ReactComponent as Undo } from "../../assets/svg/outlined_actions_undo.svg";

export interface Information {
  title: string;
  fields: { title: string; value: string }[];
}

export interface Button {
  text: string;
  testId: string;
  iconProps?: { name: string };
  action: () => void;
}

export interface StatusTemplate {
  title: string;
  description?: string;
  figure: ReactElement;
  details?: {
    title: string;
    description: string;
  };
  "data-testid": string;
  mainButton?: Button;
  secondaryButton?: Button;
  information?: Information;
  centralized?: boolean;
}

const StatusTemplate: FC<StatusTemplate> = ({
  title,
  description,
  figure,
  mainButton,
  secondaryButton,
  details,
  "data-testid": testId,
  information,
  centralized,
}) => {
  return (
    <Layout>
      <Container>
        <Box className={centralized ? "centralize" : ""}>
          <figure>{figure}</figure>
          <Typography
            variant="heading2"
            className="title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography
            variant="paragraph1"
            className="description"
            color="black.defaultT70"
            dangerouslySetInnerHTML={{ __html: description }}
            data-testid={testId}
          />

          {mainButton && (
            <Box
              className="main-button"
              data-testid={mainButton.testId}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => {
                mainButton.action();
              }}
            >
              {mainButton.text}
              <Undo />
            </Box>
          )}

          {secondaryButton && (
            <Button
              className="secondary-button"
              data-testid={secondaryButton.testId}
              variant="contained"
              styleVariant="white"
              extended
              onClick={() => {
                secondaryButton.action();
              }}
              iconProps={secondaryButton.iconProps}
            >
              {secondaryButton.text}
            </Button>
          )}

          {details && (
            <>
              <h6>Mais detalhes</h6>
              <div className="details">
                <h4>{details.title}</h4>
                <p>{details.description}</p>
              </div>
            </>
          )}

          {information && (
            <Box
              display="flex"
              flexDirection="column"
              borderTop="1px solid #f0f1f5"
              className="box-charging-details"
              paddingTop={spacing(40)}
            >
              <Typography
                variant="heading4"
                marginBottom={24}
                strong
                data-testid="homeChargingPaymentMethodsLabel"
              >
                {information.title}
              </Typography>
              {information.fields.map((field) => (
                <Box key={field.title}>
                  <Typography variant="paragraph2" strong>
                    {field.title}
                  </Typography>
                  <Typography variant="paragraph2" className="values-details">
                    {field.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default StatusTemplate;
