import { create } from "apisauce";
import Env from "../config/Env";

const API = create({
  baseURL: Env.TOKENIZATION_URL,
});

export interface CardRequest {
  card_number: string;
  encrypted_pan?: string;
  card_brand: string;
  holder_name: string;
  expiration_month: number;
  expiration_year: number;
  security_code: string;
}

class TokenizationService {
  static async tokenize(card: CardRequest) {
    const response = await API.post<{ id: string }>("/tokens", card);
    if (response.ok) {
      return response.data!.id;
    }
    throw response;
  }
}

export default TokenizationService;
