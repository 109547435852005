const config = {
  local: {
    MODE: "local",
    TOKENIZATION_URL: "http://localhost",
    CHECKOUT_URL: "http://localhost",
    CHECKOUT_TIMEOUT: 100,
    THREE_DS_POOLING_INTERVAL: 25,
    THREE_DS_POOLING_MAX_ERRORS: 2,
    AMPLITUDE_API_KEY: "",
    PAN_ENCRYPTION_FINGERPRINT: `FE:0F:4D:2F:7F:38:27:A7:03:44:FA:47:40:9A:71:D0:8D:A9:2C:49`,
    PAN_ENCRYPTION_PUBLIC_KEY: `-----BEGIN CERTIFICATE-----
MIIFajCCA1KgAwIBAgIIDzMLWm4x4eUwDQYJKoZIhvcNAQELBQAwgbgxEzARBgoJ
kiaJk/IsZAEZFgNjb20xGjAYBgoJkiaJk/IsZAEZFgptYXN0ZXJjYXJkMQswCQYD
VQQGEwJCRTEdMBsGA1UEChMUTWFzdGVyQ2FyZCBXb3JsZHdpZGUxGzAZBgNVBAsT
EkNvcnBvcmF0ZSBTZWN1cml0eTE8MDoGA1UEAxMzTWFzdGVyQ2FyZCBQUkQgQXBw
bGljYXRpb24gSW5mcmFzdHJ1Y3R1cmUgU3ViIENBIEcyMB4XDTIxMDYxNzIwMzIw
NVoXDTI1MDYxNjIwMzIwNVowga8xKzApBgNVBAMMInBhbmVuYy5tdGYubWlzZXJ2
ZXIubWFzdGVyY2FyZC5jb20xFDASBgNVBAsMC210Zi1lbmMta2V5MTQwMgYDVQQK
DCtNYXN0ZXJDYXJkIFdvcmxkV2lkZSAtIENvbW1vbiBQcm9kSW5mcmEgU1NMMRQw
EgYDVQQHDAtTYWludCBMb3VpczERMA8GA1UECAwITWlzc291cmkxCzAJBgNVBAYT
AlVTMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzS3cHWu96wpk+4OG
VDUnPg+wQZXqKiGpdTOFEBhrNnfTi8y5K8YcL4RONAys5mJMAWiGkAw/X73fZRMp
OCekaqCHhmHuCySFEX+VDWmZ7eiIacvcDMAAqgP9yD3LDRwxxr7Dnnm1krJSyACK
rwrVbwwCvl2+OXN1C+GkrlF7PGEeZl+sdS4KvRtGlCdJIvU+dRYR+NVSV1G6MT3P
ec7+CNd9x+mwpyybVVblD75lvsz4gWtHyKyw2ssC4Irg8ES5kFzgAclXqeGX3Aqm
1uE0KX4mpbGzXNUsP1eeoWiWnRnZIWUGaiJ9D8UAXW5jeusAGIP14xCe1TJeJqoE
ery9SQIDAQABo38wfTAOBgNVHQ8BAf8EBAMCAKAwDAYDVR0TAQH/BAIwADAdBgNV
HSUEFjAUBggrBgEFBQcDAQYIKwYBBQUHAwIwHQYDVR0OBBYEFBVNWc79zd+Ebscd
akK/BE/7M22nMB8GA1UdIwQYMBaAFEugoiCvMvBmXcsZeZiYsmzl7SfUMA0GCSqG
SIb3DQEBCwUAA4ICAQCAjopb8wsUWO5XY2LzFCVSiMq9hcuOZl24uNJecgUnU+kJ
AoAYx2M0hgm1d9E39n5OYDGqQa1Rwp3ji1umn+XllEtsBHM2cIo0yHvBlJQpg1Ja
WDD5uE7ja9QOiOcxl09QMRu+khc7cNRTETNs6KnH23je/7UzBlfaGJIt3k5YYJfi
S1MMddk6zIZnhtSHkFqhxyUBKeVUTxK974v9pxNMdS69bzvF65j026XEXCM2rHLC
FgB8nx8ohQwg2MFuMY3gGsKmpR3clRelEpiED/x2bfcDpPUGTpDUkkwsB/WyVYQb
KPd6GQZaxodW5NCgKSG4mU+WuniWTziQi3e7PtIjJb9fAkDzJQn/RayWwMOJ3SSb
jilGuoNzFn6UhjmN1jcCRmmCbW7tMu7ya0E8vDbz1HGbAMcR1h+mJPKyvguU7kvw
RwbWVbDpQx8HJOkwURbVwnlF+3J+0ibammd8XbYJ/V1KSIdSOxhoEARdTDfDAnA/
1hZyAOcDBAYypbEIgsStF+mP7fssHsn7uLteFOg+qQTaWvJwtaFMFb82phY9jW1W
V6kGrTMTvFNM+QmZ4g7/nHA+A5dGs9IVtv/JFuDFvxbED51N8O5spfkg/PBtxxQ4
d7BIXSLgdjIBGDSrM6QNSGyY7YyZmLUJyrsJT9E3QZorlB2bS7RzLJPEVvL7Hw==
-----END CERTIFICATE-----`,
  },
  staging: {
    MODE: "staging",
    TOKENIZATION_URL: "https://staging-tokenization-checkout.nustg.com.br",
    CHECKOUT_URL:
      "https://staging-global-webapp-proxy.nustg.com.br/api/gallywix",
    CHECKOUT_TIMEOUT: 4000,
    THREE_DS_POOLING_INTERVAL: 2000,
        THREE_DS_POOLING_MAX_ERRORS: 5,
    AMPLITUDE_API_KEY: "cfe8bad2a9c63e00235ddc091a48122c",
    PAN_ENCRYPTION_FINGERPRINT: `FE:0F:4D:2F:7F:38:27:A7:03:44:FA:47:40:9A:71:D0:8D:A9:2C:49`,
    PAN_ENCRYPTION_PUBLIC_KEY: `-----BEGIN CERTIFICATE-----
MIIFajCCA1KgAwIBAgIIDzMLWm4x4eUwDQYJKoZIhvcNAQELBQAwgbgxEzARBgoJ
kiaJk/IsZAEZFgNjb20xGjAYBgoJkiaJk/IsZAEZFgptYXN0ZXJjYXJkMQswCQYD
VQQGEwJCRTEdMBsGA1UEChMUTWFzdGVyQ2FyZCBXb3JsZHdpZGUxGzAZBgNVBAsT
EkNvcnBvcmF0ZSBTZWN1cml0eTE8MDoGA1UEAxMzTWFzdGVyQ2FyZCBQUkQgQXBw
bGljYXRpb24gSW5mcmFzdHJ1Y3R1cmUgU3ViIENBIEcyMB4XDTIxMDYxNzIwMzIw
NVoXDTI1MDYxNjIwMzIwNVowga8xKzApBgNVBAMMInBhbmVuYy5tdGYubWlzZXJ2
ZXIubWFzdGVyY2FyZC5jb20xFDASBgNVBAsMC210Zi1lbmMta2V5MTQwMgYDVQQK
DCtNYXN0ZXJDYXJkIFdvcmxkV2lkZSAtIENvbW1vbiBQcm9kSW5mcmEgU1NMMRQw
EgYDVQQHDAtTYWludCBMb3VpczERMA8GA1UECAwITWlzc291cmkxCzAJBgNVBAYT
AlVTMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzS3cHWu96wpk+4OG
VDUnPg+wQZXqKiGpdTOFEBhrNnfTi8y5K8YcL4RONAys5mJMAWiGkAw/X73fZRMp
OCekaqCHhmHuCySFEX+VDWmZ7eiIacvcDMAAqgP9yD3LDRwxxr7Dnnm1krJSyACK
rwrVbwwCvl2+OXN1C+GkrlF7PGEeZl+sdS4KvRtGlCdJIvU+dRYR+NVSV1G6MT3P
ec7+CNd9x+mwpyybVVblD75lvsz4gWtHyKyw2ssC4Irg8ES5kFzgAclXqeGX3Aqm
1uE0KX4mpbGzXNUsP1eeoWiWnRnZIWUGaiJ9D8UAXW5jeusAGIP14xCe1TJeJqoE
ery9SQIDAQABo38wfTAOBgNVHQ8BAf8EBAMCAKAwDAYDVR0TAQH/BAIwADAdBgNV
HSUEFjAUBggrBgEFBQcDAQYIKwYBBQUHAwIwHQYDVR0OBBYEFBVNWc79zd+Ebscd
akK/BE/7M22nMB8GA1UdIwQYMBaAFEugoiCvMvBmXcsZeZiYsmzl7SfUMA0GCSqG
SIb3DQEBCwUAA4ICAQCAjopb8wsUWO5XY2LzFCVSiMq9hcuOZl24uNJecgUnU+kJ
AoAYx2M0hgm1d9E39n5OYDGqQa1Rwp3ji1umn+XllEtsBHM2cIo0yHvBlJQpg1Ja
WDD5uE7ja9QOiOcxl09QMRu+khc7cNRTETNs6KnH23je/7UzBlfaGJIt3k5YYJfi
S1MMddk6zIZnhtSHkFqhxyUBKeVUTxK974v9pxNMdS69bzvF65j026XEXCM2rHLC
FgB8nx8ohQwg2MFuMY3gGsKmpR3clRelEpiED/x2bfcDpPUGTpDUkkwsB/WyVYQb
KPd6GQZaxodW5NCgKSG4mU+WuniWTziQi3e7PtIjJb9fAkDzJQn/RayWwMOJ3SSb
jilGuoNzFn6UhjmN1jcCRmmCbW7tMu7ya0E8vDbz1HGbAMcR1h+mJPKyvguU7kvw
RwbWVbDpQx8HJOkwURbVwnlF+3J+0ibammd8XbYJ/V1KSIdSOxhoEARdTDfDAnA/
1hZyAOcDBAYypbEIgsStF+mP7fssHsn7uLteFOg+qQTaWvJwtaFMFb82phY9jW1W
V6kGrTMTvFNM+QmZ4g7/nHA+A5dGs9IVtv/JFuDFvxbED51N8O5spfkg/PBtxxQ4
d7BIXSLgdjIBGDSrM6QNSGyY7YyZmLUJyrsJT9E3QZorlB2bS7RzLJPEVvL7Hw==
-----END CERTIFICATE-----`,
  },

  production: {
    MODE: "production",
    TOKENIZATION_URL: "https://tokenization-checkout.nubank.com.br",
    CHECKOUT_URL: "https://prod-global-webapp-proxy.nubank.com.br/api/gallywix",
    CHECKOUT_TIMEOUT: 4000,
    THREE_DS_POOLING_INTERVAL: 2000,
        THREE_DS_POOLING_MAX_ERRORS: 5,
    AMPLITUDE_API_KEY: "a042652874b2ef595b4224f644c54fa7",
    PAN_ENCRYPTION_FINGERPRINT: `8D:B2:A3:9B:F2:50:AC:27:61:FF:4C:2F:2F:AA:CB:7B:3D:C9:34:16`,
    PAN_ENCRYPTION_PUBLIC_KEY: `-----BEGIN CERTIFICATE-----
MIIFbDCCA1SgAwIBAgIIDzMLWm47u2kwDQYJKoZIhvcNAQELBQAwgbgxEzARBgoJ
kiaJk/IsZAEZFgNjb20xGjAYBgoJkiaJk/IsZAEZFgptYXN0ZXJjYXJkMQswCQYD
VQQGEwJCRTEdMBsGA1UEChMUTWFzdGVyQ2FyZCBXb3JsZHdpZGUxGzAZBgNVBAsT
EkNvcnBvcmF0ZSBTZWN1cml0eTE8MDoGA1UEAxMzTWFzdGVyQ2FyZCBQUkQgQXBw
bGljYXRpb24gSW5mcmFzdHJ1Y3R1cmUgU3ViIENBIEcyMB4XDTIxMDgxMDIwMjE0
NloXDTI1MDgwOTIwMjE0NlowgbExLDAqBgNVBAMMI3BhbmVuYy5wcm9kLm1pc2Vy
dmVyLm1hc3RlcmNhcmQuY29tMRUwEwYDVQQLDAxwcm9kLWVuYy1rZXkxNDAyBgNV
BAoMK01hc3RlckNhcmQgV29ybGRXaWRlIC0gQ29tbW9uIFByb2RJbmZyYSBTU0wx
FDASBgNVBAcMC1NhaW50IExvdWlzMREwDwYDVQQIDAhNaXNzb3VyaTELMAkGA1UE
BhMCVVMwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDnFlh2ENPopE4u
JaZ1xzZVWKXouYSsSBEyQm0Odp/BA5/Vs9kcGeej9XLvIMWxM2JeMc95FKrxU/Ok
cdG7ekPrMWLfS2upneaqVdrEoesxUoA7l4LDltHrXWuBkGLfcD1Qs/8jKFubN0xK
SrgfTcWGu2kaRI4WrP9/jH3JYX4OW5wk92kdLF3uxy0YqHHai7v1qvare9wI3jJb
uVPwGeTvwkpfhOldu1NU48NppdrR+tW1rgEqwTOpL6Bo6jW4Len2ysJFhcfsS2My
9FbFPxfRwhnidavvpd7oRXqvJkBt9rHCB9j4gaNpc/QofWjbeUjBsjeG9U61DMJJ
pn9sr3CLAgMBAAGjfzB9MA4GA1UdDwEB/wQEAwIAoDAMBgNVHRMBAf8EAjAAMB0G
A1UdJQQWMBQGCCsGAQUFBwMBBggrBgEFBQcDAjAdBgNVHQ4EFgQUDErC6mPRTs+6
pVcpwL+YRG8k6vAwHwYDVR0jBBgwFoAUS6CiIK8y8GZdyxl5mJiybOXtJ9QwDQYJ
KoZIhvcNAQELBQADggIBAF/u2eMhswoh6Ppte+ZPMz4n+veNRfEJFE7Dk3egdivp
rrvRUEpWYivUWt9AF5bXRsY/Wx12kROTuQ8dsqbfR4iTVc+RgpeC6tvqGdi5oEem
GKDYFula0rEILWk5R2s54nZrRIowOMLwtZ2uyY0AI46nvBbzWRuIMkgRl3g3EEdA
qnif/bBQWIzsvc71/hCUGm86SBiFH3+4VlTjIoY+hFGy86/QYeuHvzj/qgQWkbqs
N6H+sCbftJmJWyGiFCl6M1PEYdt1qqiwVSXDaHQO0WVWCwMbKy2QhCWrwn0nkKmd
e2nmn5i/jM3KBXPiZ2+Z+xOvP08I+84u0mGRhUD8Cqc+rG02pC52emgvKaNuWp0N
/UUKloQPfaYsIPkdt+kjD8kXyLsBbyCRy8o6vQ3RchPWK6ddxPi0NvXZLSbuwa82
XSEP4Ss0UzqI1Szd1PUT3stWtN06lJc/AVrEtXOAwvAQUEdp8e23pExaDoeq+wEc
qjJkmZPxFZV2hVUG7cHB90PIum83hn2ZGx7DpImu2EnVOL+H9LxOJnVNGJ06/i1J
MGDzxffDSJArszWAmHyZX5hIDnw7zFeWN5rT2NaQ7ElIhjjl0o0pegSI6q9CCC7a
pqyOnek7FRZn16DI9jeyFvEI8TqC9gKvYnacMKxE3PFWAY/FuuJAqutQ+o8gMd+v
-----END CERTIFICATE-----`,
  },
};

const Env = config[process.env.REACT_APP_ENVIRONMENT || "local"];

export default Env;
