import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import styled from "styled-components";

export const Container = styled.div`
  .box-charging-details div {
    display: flex;
    justify-content: space-between;
  }

  .box-charging-details p {
    margin-bottom: ${spacing("4x")};
  }

  @media (min-width: 768px) {
    .text-amount {
      font-size: 3rem;
      line-height: 1;
    }
  }
`;
