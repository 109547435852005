import * as amplitude from "@amplitude/analytics-browser";
import Env from "../config/Env";

amplitude.init(Env.AMPLITUDE_API_KEY);

class AmplitudeService {
  public static async screenViewed(eventProps: Record<string, unknown>) {
    return amplitude.logEvent("screen__viewed", eventProps);
  }

  public static async buttonClicked(eventProps: Record<string, unknown>) {
    return amplitude.logEvent("button__clicked", eventProps);
  }

  public static async error(eventProps: Record<string, unknown>) {
    return amplitude.logEvent("error", eventProps);
  }
}

export default AmplitudeService;
