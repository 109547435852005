import { FC } from "react";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import Box from "@nubank/nuds-web/components/Box/Box";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import { Container } from "./styles";

interface ChargingButton {
  leadingIcon: JSX.Element;
  trailingIcon: JSX.Element;
  title: string;
  subtitle?: string;
  testid: string;
  onClick: () => void;
}

const ChargingButton: FC<ChargingButton> = ({
  leadingIcon,
  trailingIcon,
  title,
  subtitle,
  testid,
  onClick,
}) => {
  return (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        backgroundColor="#efefef"
        borderRadius={spacing("4x")}
        padding={spacing("4x", "6x")}
        marginBottom={spacing("2x")}
        onClick={onClick}
        data-testid={testid}
      >
        {leadingIcon}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          gridRowGap={spacing("1x")}
          paddingLeft={spacing("4x")}
        >
          <Typography variant="subtitle2" strong>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="paragraph2" color="black.defaultT70">
              {subtitle}
            </Typography>
          )}
        </Box>
        {trailingIcon}
      </Box>
    </Container>
  );
};

export default ChargingButton;
