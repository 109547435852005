import { spacing, nuDSColor } from "@nubank/nuds-web/styles/themeUtils";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  label > div > span {
    font-size: 18px;
  }

  .seller-name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: ${spacing("4x")};
  }

  .color-subtitle {
    color: ${nuDSColor("black", "defaultT70")};
  }

  .qrcode-container {
    img {
      height: 300px;
    }

    border: 2px solid ${nuDSColor("black", "defaultT10")};
    border-radius: 16px;
  }
`;
