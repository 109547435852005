/* eslint-disable camelcase */
import {
  SimpleCharging,
  OptionsCharging,
  InstallmentOption,
  Method,
} from "../services/checkout.service";
import { maskTaxId } from "../helpers/mask";
import {
  formatDateWithoutTime,
  formatToBRL,
  formatToPercentage,
} from "../helpers/formatter";

export interface Charging {
  publicId: string;
  seller: {
    name: string;
    taxId: string;
  };
  message?: string;
  amount: string;
  amountNupayWithPassOnFees: string;
  dueDate: string;
  paymentMethods: Method[];
  installmentsOptions?: InstallmentOption[];
  eventProps: Record<string, string>;
  threeDsEnabled: boolean;
  paymentErrorScreensEnabled: boolean;
}

export function responseToModel(
  publicId: string,
  charging: SimpleCharging,
  options?: OptionsCharging
): Charging {
  return {
    publicId,
    amount: formatToBRL(charging.amount),
    amountNupayWithPassOnFees: formatToBRL(
      `${Number(charging.amount) / (1 - 0.0269)}`
      // 0.0269 fee hardcoded. In the future it should come in charging request.
    ),
    seller: {
      name: charging.seller.name,
      taxId: maskTaxId(charging.seller.tax_id),
    },
    dueDate: formatDateWithoutTime(charging.due_date),
    paymentMethods: charging.payment_methods.map(
      ({
        method_type,
        allowed_installments,
        brands,
        brcode,
        qrcode_image,
        available,
        reason,
        enabled,
        pass_on_fees_enabled,
      }) => ({
        method_type,
        allowed_installments,
        brands,
        brcode,
        qrcode_image,
        available,
        reason,
        enabled,
        pass_on_fees_enabled,
      })
    ),
    installmentsOptions: charging.installments_options?.map(
      ({ number, total_amount, installment_amount, fee }) => ({
        number,
        total_amount: formatToBRL(total_amount),
        installment_amount: formatToBRL(installment_amount),
        fee: formatToPercentage(fee),
      })
    ),
    eventProps: {
      charge_id: publicId,
      charge_amount: charging.amount,
      merchant_name: charging.seller.name,
      merchant_id: charging.seller.tax_id,
      version: charging.payment_methods.length > 1 ? "multi_rail" : "cc_only",
      product: "contapj",
    },
    threeDsEnabled: options ? options["3ds_enabled"] : false,
    paymentErrorScreensEnabled: options ? options.payment_error_screens_enabled : false,
  };
}
