import React from "react";
import ReactDOM from "react-dom";
import NuDSProvider from "@nubank/nuds-web/components/NuDSProvider/NuDSProvider";
import Box from "@nubank/nuds-web/components/Box/Box";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Pages from "./pages";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <NuDSProvider>
        <Box
          tag="section"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Switch>
            <Route path="/:publicId" component={Pages} />
          </Switch>
        </Box>
      </NuDSProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
