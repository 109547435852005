import { FC } from "react";
import StatusTemplate from "../../templates/StatusTemplate";
import { ReactComponent as TransferSucceeded } from "../../assets/svg/nuis_light_the_transfer_was_succeed.svg";
import Text from "../../resources/text";
import { CheckoutRequest } from "../../hooks/useCharging";
import { Charging } from "../../adapters/charging.adapter";
import { formatDateNowWithTime } from "../../helpers/formatter";
import { maskPanWithBrandName } from "../../helpers/mask";
import { InstallmentOption } from "../../services/checkout.service";

interface Approved {
  charging: Charging;
  request: CheckoutRequest;
  installmentSelected?: InstallmentOption;
}

const Approved: FC<Approved> = ({ charging, request, installmentSelected }) => {
  const fields = [
    {
      title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_CARD_HOLDER,
      value: request.holderName,
    },
    {
      title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_CARD_NUMBER,
      value: maskPanWithBrandName(request.pan),
    },
    {
      title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_COBRANCA_ID,
      value: charging.publicId,
    },
    {
      title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_PAYMENT_DATE,
      value: formatDateNowWithTime(),
    },
    {
      title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_COMPANY,
      value: charging.seller.name,
    },
    {
      title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_CNPJ,
      value: charging.seller.taxId,
    },
  ];

  if (installmentSelected) {
    fields.splice(0, 0, {
      title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_INSTALLMENTS,
      value: `${installmentSelected.number}x ${installmentSelected.installment_amount} (${installmentSelected.total_amount})`,
    });
  }

  return (
    <StatusTemplate
      data-testid="approved"
      figure={<TransferSucceeded />}
      title={`${Text.STATUS_APPROVED_TITLE} ${charging.seller.name}`}
      information={{
        title: Text.STATUS_APPROVED_PAYMENT_SUMMARY_TITLE,
        fields,
      }}
    />
  );
};

export default Approved;
