const Text = {
  HOME_CHARGING_LABEL: "Valor da cobrança",
  HOME_CHARGING_BUTTON_01: "Pagar com Nubank",
  HOME_CHARGING_BUTTON_01_SUBTITLE_01: "Em até {{value}}x sem juros",
  HOME_CHARGING_BUTTON_01_SUBTITLE_02: "Mais rápido e seguro",
  HOME_CHARGING_BUTTON_01_SUBTITLE_03: "A partir de {{value}}",
  HOME_CHARGING_BUTTON_02: "Cartão de crédito",
  HOME_CHARGING_BUTTON_02_SUBTITLE_01: "Em até {{value}}x sem juros",
  HOME_CHARGING_BUTTON_02_SUBTITLE_02: "Em até {{value}}x com juros",
  HOME_CHARGING_BUTTON_02_SUBTITLE_03: "Mastercard, Visa e Elo",
  HOME_CHARGING_BUTTON_02_SUBTITLE_04: "A partir de {{value}}",
  HOME_CHARGING_BUTTON_02_SUBTITLE_05: "À vista",
  HOME_CHARGING_BUTTON_03: "Pagar com Pix",
  HOME_CHARGING_DUE_DATE_LABEL: "Vencimento",
  HOME_CHARGING_CNPJ_LABEL: "CNPJ",

  PAYMENT_VALIDATION_ERROR_REQUIRED: "Campo obrigatório",
  PAYMENT_VALIDATION_ERROR_FULL_NAME: "Digite seu nome completo",
  PAYMENT_VALIDATION_ERROR_CHARACTER_FULL_NAME: "Digite um nome válido",
  PAYMENT_VALIDATION_ERROR_EMAIL: "Digite um email válido",
  PAYMENT_VALIDATION_ERROR_TAXID: "Digite um CPF ou CNPJ válido",
  PAYMENT_VALIDATION_ERROR_PHONE: "Digite um número válido com DDD",
  PAYMENT_VALIDATION_ERROR_PAN: "Digite um número de cartão válido",
  PAYMENT_VALIDATION_ERROR_TYPE_CARD:
    "Só aceitamos as bandeiras Visa, Mastercard e Elo",
  PAYMENT_VALIDATION_ERROR_EXPIRATION_DATE:
    "A data de vencimento está no passado.",
  PAYMENT_VALIDATION_ERROR_MONTH_EXPIRATION_DATE:
    "Digite uma data de vencimento válida",
  PAYMENT_VALIDATION_ERROR_CVV: "Código incompleto",
  PAYMENT_VALIDATION_ERROR_CEP: "Digite um CEP válido",
  PAYMENT_TITLE: "Pagar com cartão",
  PAYMENT_CUSTOMER_TITLE: "Dados pessoais ",
  PAYMENT_CUSTOMER_SUBTITLE: "Aqui são os dados da pessoa titular do cartão",
  PAYMENT_CUSTOMER_FULL_NAME_LABEL: "Nome completo",
  PAYMENT_CUSTOMER_EMAIL_LABEL: "E-mail",
  PAYMENT_CUSTOMER_TAXID_LABEL: "CPF/CNPJ",
  PAYMENT_CUSTOMER_PHONE_LABEL: "Telefone",
  PAYMENT_CUSTOMER_PHONE_PLACEHOLDER: "(DDD)+Número",
  PAYMENT_CARD_TITLE: "Dados do cartão",
  PAYMENT_CARD_NUMBER_LABEL: "Número do Cartão",
  PAYMENT_CARD_HOLDER_NAME_LABEL: "Nome da pessoa titular",
  PAYMENT_CARD_EXPIRATION_DATE_LABEL: "Vencimento",
  PAYMENT_CARD_EXPIRATION_DATE_PLACEHOLDER: "MM/AA",
  PAYMENT_CARD_CVV_LABEL: "CVV",
  PAYMENT_CUSTOMER_ADDRESS_CEP_LABEL: "CEP",
  PAYMENT_CUSTOMER_ADDRESS_NUMBER_LABEL: "Número",
  PAYMENT_INSTALLMENT_LABEL: "Pagamento",
  PAYMENT_INSTALLMENT_SINGLE: "(à vista)",
  PAYMENT_INSTALLMENT: "Pagar em",
  PAYMENT_PAY_LABEL: "Pagar",
  PAYMENT_MODAL_TITLE: "Parcelamento",
  PAYMENT_MODAL_BADGE: "Sem juros",
  PAYMENT_TERMS_AND_CONDITIONS_LABEL: "Termos e condições de uso",

  PIX_PAYMENT_TITLE: "Pagar com Pix",
  PIX_PAYMENT_SUBTITLE: "Escaneie o QR code ou copie o código",
  PIX_PAYMENT_INSTRUCTIONS_TITLE: "Instruções de Pagamento",
  PIX_PAYMENT_INSTRUCTIONS_FIRST_ITEM: "1. Abra o aplicativo do seu banco;",
  PIX_PAYMENT_INSTRUCTIONS_SECOND_ITEM:
    "2. Entre na área Pix e busque a opção de pagar com Pix Copia e Cola;",
  PIX_PAYMENT_INSTRUCTIONS_THIRD_ITEM:
    "3. Insira o código copiado desta cobrança;",
  PIX_PAYMENT_INSTRUCTIONS_FOURTH_ITEM: "4. Revise o pagamento e confirme.",
  PIX_PAYMENT_INSTRUCTIONS_LAST_PARAGRAPH:
    "Se estiver no computador, abra o aplicativo do seu banco e escaneie a imagem acima com a câmera do celular.",
  PIX_PAYMENT_INSTRUCTIONS_CTA: "Copiar código do QR Code",
  PIX_PAYMENT_INSTRUCTIONS_CTA_AFTER: "Código copiado!",

  POPUP_PAY_WITH_NUPAY_TITLE: "Benefícios exclusivos",
  POPUP_PAY_WITH_NUPAY_DESCRIPTION:
    "Pague com o app do Nubank e aproveite as vantagens: <li>Parcelamento em até 24x; </li> <li>Pode ter limite extra; </li> <li>Não precisa preencher os dados do seu cartão.</li>",
  POPUP_PAY_WITH_NUPAY_BUTTON: "Pagar com app",

  POPUP_CC_THRESHOLD_TITLE: "Forma de pagamento indisponível",
  POPUP_CC_THRESHOLD_DESCRIPTION:
    'O destinatário atingiu o limite de recebimento via cartão de crédito. <br> Se você for cliente do Nubank, use a opção "<strong>Pagar com Nubank</strong>" ou pague com <strong>Pix</strong>.',
  POPUP_CC_THRESHOLD_BUTTON: "Voltar",

  STATUS_APPROVED_TITLE: "Tudo certo! <br> Você acabou de pagar <br>",
  STATUS_APPROVED_PAYMENT_SUMMARY_TITLE: "Resumo do pagamento",
  STATUS_APPROVED_PAYMENT_SUMMARY_INSTALLMENTS: "Pagamento",
  STATUS_APPROVED_PAYMENT_SUMMARY_CARD_HOLDER: "Titular do cartão",
  STATUS_APPROVED_PAYMENT_SUMMARY_CARD_NUMBER: "Cartão",
  STATUS_APPROVED_PAYMENT_SUMMARY_COBRANCA_ID: "ID de cobrança",
  STATUS_APPROVED_PAYMENT_SUMMARY_PAYMENT_DATE: "Data de pagamento",
  STATUS_APPROVED_PAYMENT_SUMMARY_COMPANY: "Empresa",
  STATUS_APPROVED_PAYMENT_SUMMARY_CNPJ: "CNPJ",
  STATUS_INSUFFICIENT_FUNDS_TITLE: "Não há limite suficiente para essa compra",
  STATUS_INSUFFICIENT_FUNDS_DESCRIPTION: 
  'Não foi possível concluir o pagamento. Tente novamente com outro cartão ou pague com Pix. <br/><br/> Clientes Nubank podem pagar no crédito, podendo ter limite adicional para essa compra.',
  STATUS_UPDATED_INFORMATION_NEEDED_TITLE: "Verifique os dados do cartão",
  STATUS_UPDATED_INFORMATION_NEEDED_DESCRIPTION: 
  'Não foi possível concluir o pagamento. Verifique os dados do cartão e tente novamente. <br/><br/> Se você for cliente Nubank, você pode pagar no crédito ou com seu saldo sem os dados do cartão. Escolha "Pagar com Nubank".',
  STATUS_CONTACT_CENTRAL_TITLE: "Entre em contato com a central do seu cartão",
  STATUS_CONTACT_CENTRAL_DESCRIPTION: 
  'Não foi possível concluir o pagamento. Contate a central do seu cartão para mais informações e tente novamente. <br/><br/> Se você for cliente Nubank, você pode pagar no crédito ou com seu saldo sem os dados do cartão. Escolha "Pagar com Nubank".',
  STATUS_LOCKED_CARD_TITLE: "Desbloqueie seu cartão para pagar",
  STATUS_LOCKED_CARD_DESCRIPTION: 
  'Não foi possível concluir o pagamento. Desbloqueie o cartão e tente novamente. <br/><br/> Se você for cliente Nubank, você pode pagar no crédito ou com seu saldo sem os dados do cartão. Escolha "Pagar com Nubank".',
  STATUS_FRAUD_TRANSACTION_TITLE: "Use outro cartão para esse pagamento",
  STATUS_FRAUD_TRANSACTION_DESCRIPTION: 
  'Não foi possível concluir o pagamento. Tente novamente com outro cartão ou pague com Pix. <br/><br/> Se você for cliente Nubank, você pode pagar no crédito ou com seu saldo sem os dados do cartão. Escolha "Pagar com Nubank".',
  STATUS_TRY_AGAIN_LATER_TITLE: "Aconteceu um erro por aqui",
  STATUS_TRY_AGAIN_LATER_DESCRIPTION: 
  'Não foi possível concluir o pagamento. Volte à tela inicial e tente utilizar outro cartão ou pague com Pix. <br/><br/> Se você for cliente Nubank, use a opção "Pagar com Nubank" e pague no crédito ou com saldo em conta.',
  STATUS_DENIED_TITLE: "Erro no pagamento",
  STATUS_DENIED_DESCRIPTION:
    'Não foi possível concluir o pagamento. Volte à tela inicial e tente utilizar outro cartão ou pague com Pix. <br/><br/> Se você for cliente do Nubank, use a opção "Pagar com Nubank" e pague no crédito ou saldo em conta.',
  STATUS_EXPIRED_TITLE: "Poxa! Esse link de pagamento expirou",
  STATUS_EXPIRED_DESCRIPTION:
    "Para conseguir realizar esse pagamento, é só pedir um novo link para a pessoa que te enviou essa cobrança.",
  STATUS_ERROR_TITLE: "Aconteceu um erro por aqui",
  STATUS_ERROR_DESCRIPTION:
    "Não se preocupe, não é com você. Esses erros podem acontecer.",
  STATUS_ERROR_TEXT_BUTTON: "Tentar novamente",
  STATUS_GENERIC_ERROR_TEXT_BUTTON: "Tentar de novo",
  STATUS_BACK_HOME: "Voltar à tela inicial",
  STATUS_PAYMENT_ERROR_BACK_HOME: "Alterar forma de pagamento",
  STATUS_NUPAY: "Pagar com Nubank",
  STATUS_NOT_FOUND_TITLE: "Não encontramos esse link de pagamento",
  STATUS_NOT_FOUND_DESCRIPTION:
    "Para conseguir realizar esse pagamento, é só pedir um novo link para a pessoa que te enviou essa cobrança.",
  STATUS_PAID_TITLE: "Tá tudo certo! <br> Esse link já tá pago",
  STATUS_PAID_DESCRIPTION: " ",
  STATUS_UNAVAILABLE_TITLE: "Serviço indisponível temporariamente",
  STATUS_UNAVAILABLE_DESCRIPTION:
    "Estamos trabalhando para atualizar e melhorar nossos serviços. Tente novamente mais tarde ou volte e pague o link com outra forma de pagamento.",
  STATUS_UNAVAILABLE_BUTTON: "Escolher outra forma de pagamento",

  FOOTER_LABEL: "Powered by Nubank",
};

export default Text;
