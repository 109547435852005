import StatusTemplate from "../../templates/StatusTemplate";
import { ReactComponent as WellDoneHiFive } from "../../assets/svg/nuis_light_well_done_hi-five.svg";
import Text from "../../resources/text";

const Paid = () => {
  return (
    <StatusTemplate
      data-testid="paid"
      figure={<WellDoneHiFive />}
      title={Text.STATUS_PAID_TITLE}
      description={Text.STATUS_PAID_DESCRIPTION}
    />
  );
};

export default Paid;
