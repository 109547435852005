import { ChangeEvent, FC, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

import Box from "@nubank/nuds-web/components/Box/Box";
import Button from "@nubank/nuds-web/components/Button/Button";
import Form from "@nubank/nuds-web/components/Form/Form";
import Icon from "@nubank/nuds-web/components/Icon/Icon";
import Link from "@nubank/nuds-web/components/Link/Link";
import TextField from "@nubank/nuds-web/components/TextField/TextField";
import Typography from "@nubank/nuds-web/components/Typography/Typography";
import { spacing } from "@nubank/nuds-web/styles/themeUtils";
import { create } from "apisauce";
import Approved from "../Approved";
import Denied from "../Denied";
import PaymentError from "../PaymentError";
import Error from "../Error";
import Challenged from "../Challenged";
import { ChallengeDetails, CheckoutRequest } from "../../hooks/useCharging";

import { ReactComponent as PopupBanner } from "../../assets/svg/popup_banner.svg";
import { ReactComponent as IconArrowRightSvg } from "../../assets/svg/icon_arrow_right.svg";

import Layout from "../../components/Layout";
import InstallmentsModal from "../../components/InstallmentsModal";
import Popup from "../../components/Popup";
import Env from "../../config/Env";
import Text from "../../resources/text";
import { Container } from "./styles";
import { Charging } from "../../adapters/charging.adapter";
import { IconStore } from "../../components/Layout/styles";
import { ReactComponent as StoreSvg } from "../../assets/svg/store.svg";
import AmplitudeService from "../../services/amplitude.service";
import { CheckoutStatus, TransactionAuthenticationResponse } from "../../services/checkout.service";
import { extractTypeCard } from "../../helpers/extract";
import { paymentErrorScreen } from "../../helpers/paymentErrorScreen";
import {
  maskPan,
  maskExpirationDate,
  maskTaxId,
  maskPhone,
  maskCep,
} from "../../helpers/mask";
import {
  isValidExpirationDate,
  isValidPan,
  isNuBrPan,
  isValidFullName,
  isValidFullNameCharacter,
  isValidTaxId,
  isValidPhone,
  isTypeCard,
  isValidMonthExpirationDate,
  isValidCardCVV,
  isValidCep,
} from "../../helpers/validation";

export interface Submit {
  values: Omit<CheckoutRequest, "fingerprintId">;
  setSubmitting: (status: boolean) => void;
  setFormErrors: () => void;
}

export interface FormikFields {
  setFieldValue: (field: string, value: string) => void;
  isDisabled: boolean;
  isSubmitting: boolean;
}

interface CardPayment {
  status?: CheckoutStatus | undefined;
  setFailedReason: React.Dispatch<
    React.SetStateAction<string | undefined>>;
  failedReason?: string | undefined;
  challengeDetails?: ChallengeDetails;
  reload: () => void;
  charging: Charging;
  onCheckout: (request: CheckoutRequest) => Promise<void>;
  onPayByNupay: () => void;
  setShowCheckout: (checkoutVisible: boolean) => void;
  setCheckoutStatus: React.Dispatch<
    React.SetStateAction<CheckoutStatus | undefined>>;
  getTransactionAuthenticationResult: () => Promise<TransactionAuthenticationResponse | null>;
}

const CardPayment: FC<CardPayment> = ({
  charging,
  onCheckout,
  onPayByNupay,
  status,
  setFailedReason,
  failedReason,
  challengeDetails,
  reload,
  setShowCheckout,
  setCheckoutStatus,
  getTransactionAuthenticationResult,
}) => {
  const [request, setRequest] = useState<CheckoutRequest>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showNupayLink, setShowNupayLink] = useState<boolean>(false);
  const [installmentsNumber, setInstallmentsNumber] = useState<number>(1);
  const [validateOnMount, setValidateOnMount] = useState<boolean>(true);
  const [addressCaption, setAddressCaption] = useState("");

  const { eventProps } = charging;
  const installmentSelected = charging.installmentsOptions?.find(
    (option) => option.number === installmentsNumber
  );

  useEffect(() => {
    setValidateOnMount(false);

    if (modalVisible) {
      AmplitudeService.screenViewed({
        entity_name: "cc_installments_modal",
        current_screen: "cc_installments_modal",
        ...eventProps,
      });
    }
  }, [modalVisible]);

  const handleSubmit = (options: Submit) => {
    const fingerprintId = (
      window.document.getElementById("fingerprint_input") as HTMLInputElement
    ).value;

    AmplitudeService.buttonClicked({
      entity_name: "pay_with_cc",
      fingerprint_id: fingerprintId,
      current_screen: "cc_checkout",
      href: `${Env.CHECKOUT_URL}/checkout/${charging.publicId}`,
      installment_number: installmentSelected?.number,
      installment_mode: installmentSelected?.fee === "0%" ? "PSJ" : "PCJ",
      ...eventProps,
    });

    const brand = extractTypeCard(options.values.pan);

    const values = {
      ...options.values,
      fingerprintId,
      brand,
      installmentsNumber,
    };

    setRequest(values);
    onCheckout(values);
  };

  const closeCheckout = () => {
    setShowCheckout(false);
  };

  const getAddress = (cep: string) => {
    if (isValidCep(cep)) {
      create({ baseURL: "https://viacep.com.br" })
        .get(`/ws/${cep}/json/`)
        .then(
          (res: any) =>
            cep.includes(res?.data?.cep) &&
            setAddressCaption(
              `${res.data.logradouro}. ${res.data.bairro}. ${res.data.localidade} - ${res.data.uf}`
            )
        );
    }
    setAddressCaption("");
  };

  if (status === CheckoutStatus.approved && request) {
    AmplitudeService.screenViewed({
      entity_name: "pbl_cc_success",
      current_screen: "pbl_cc_success",
      installments: installmentsNumber,
      ...eventProps,
    });

    return (
      <Approved
        charging={charging}
        request={request}
        installmentSelected={installmentSelected}
      />
    );
  }

  if (status === CheckoutStatus.denied) {
    const returnToHome = () => {
      window.location.reload();
    };

    AmplitudeService.screenViewed({
      entity_name: "pbl_cc_denied",
      current_screen: "pbl_cc_denied",
      installments: installmentsNumber,
      failed_reason: failedReason,
      ...eventProps,
    });

    if (charging.paymentErrorScreensEnabled && failedReason) {
      const paymentErrorScreenParams = paymentErrorScreen(failedReason);

      return (
        <PaymentError
          testId = {paymentErrorScreenParams.testId}
          title = {paymentErrorScreenParams.title}
          description = {paymentErrorScreenParams.description}
          figure = {<paymentErrorScreenParams.figure/>}
          primaryButtonAction = {returnToHome}
          secondaryButtonAction = {onPayByNupay}
        />
      );
    }

    return (
      <Denied
        primaryButtonAction={returnToHome}
        secondaryButtonAction={onPayByNupay}
      />
    );
  }

  if (status === CheckoutStatus.challenged && challengeDetails?.challengeUrl && challengeDetails?.creq) {
    AmplitudeService.screenViewed({
      entity_name: "pbl_cc_challenged",
      current_screen: "pbl_cc_challenged",
      installments: installmentsNumber,
      ...eventProps,
    });

    return (
      <Challenged
        getTransactionAuthenticationResult={getTransactionAuthenticationResult}
        challengeUrl={challengeDetails.challengeUrl}
        creq={challengeDetails.creq}
        setCheckoutStatus={setCheckoutStatus}
        setFailedReason={setFailedReason}
      />
    );
  }

  if (status === CheckoutStatus.error) {
    AmplitudeService.screenViewed({
      entity_name: "pbl_cc_error",
      current_screen: "pbl_cc_error",
      installments: installmentsNumber,
      ...eventProps,
    });

    return <Error retry={reload} />;
  }

  return (
    <Layout
      leadingIcon={{
        iconName: "arrow-left",
        color: "black.defaultT70",
        onClick: closeCheckout,
      }}
      footerMarginBottom="80px"
    >
      <Popup
        show={{ showPopup, setShowPopup }}
        popupBanner={<PopupBanner className="border_radius_top" />}
        title={Text.POPUP_PAY_WITH_NUPAY_TITLE}
        description={Text.POPUP_PAY_WITH_NUPAY_DESCRIPTION}
        button={Text.POPUP_PAY_WITH_NUPAY_BUTTON}
        buttonIcon={<IconArrowRightSvg fill="#fff" />}
        buttonAction={() => {
          AmplitudeService.buttonClicked({
            entity_name: "pay_with_nupay",
            current_screen: "nupay_pop_up",
            ...eventProps,
          });
          onPayByNupay();
        }}
      />
      <Container data-testid="checkout">
        <Box
          display="flex"
          borderBottom="1px solid #f0f1f5"
          alignItems="center"
          justifyContent="space-between"
          padding={spacing("4x", "6x")}
        >
          <Box display="flex" alignItems="center">
            <IconStore>
              <StoreSvg />
            </IconStore>
            <Typography
              variant="subtitle2"
              marginLeft={8}
              strong
              className="seller-name"
            >
              {charging.seller.name}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="subtitle2"
              color="primary"
              marginLeft={8}
              strong
            >
              {installmentSelected
                ? installmentSelected.total_amount
                : charging.amount}
            </Typography>
          </Box>
        </Box>

        <Box
          tag="section"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="left"
          gridGap="8x"
          data-testid="payment"
          padding={spacing("12x", "6x", "6x")}
        >
          <Form
            extraValidations={{
              isValidFullName,
              isValidFullNameCharacter,
              isValidTaxId,
              isValidPhone,
              isValidPan,
              isNuBrPan,
              isValidExpirationDate,
              isValidMonthExpirationDate,
              isTypeCard,
              isValidCardCVV,
              isValidCep,
            }}
          >
            <Form.Step
              initialValues={
                request || {
                  fullName: "",
                  email: "",
                  taxId: "",
                  phone: "",
                  pan: "",
                  holderName: "",
                  expirationDate: "",
                  cvv: "",
                  installmentsNumber: "",
                  postcode: "",
                }
              }
              validateOnMount={validateOnMount}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, isDisabled, isSubmitting }: FormikFields) => (
                <>
                  <Typography
                    variant="heading2"
                    className="body-title"
                    marginBottom={32}
                  >
                    {Text.PAYMENT_TITLE}
                  </Typography>

                  <Box display="grid" gridGap={spacing("6x")}>
                    <Typography variant="heading4">
                      {Text.PAYMENT_CARD_TITLE}
                    </Typography>
                    <Box>
                      <TextField
                        disabled={isSubmitting || showPopup}
                        id="pan"
                        inputmode="numeric"
                        type="tel"
                        name="pan"
                        data-testid="pan"
                        syncValidations={{
                          required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                          isValidPan: Text.PAYMENT_VALIDATION_ERROR_PAN,
                          isTypeCard: Text.PAYMENT_VALIDATION_ERROR_TYPE_CARD,
                        }}
                        onChange={({
                          target: { value },
                        }: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("pan", maskPan(value));
                          setShowNupayLink(false);
                        }}
                        onBlur={({
                          target: { value },
                        }: ChangeEvent<HTMLInputElement>) => {
                          const maskedPan = maskPan(value);
                          if (isValidPan(maskedPan) && isNuBrPan(maskedPan)) {
                            setShowPopup(true);
                            setShowNupayLink(true);
                            AmplitudeService.screenViewed({
                              entity_name: "nupay_pop_up",
                              current_screen: "cc_checkout",
                              ...eventProps,
                            });
                          }
                        }}
                        label={Text.PAYMENT_CARD_NUMBER_LABEL}
                      />
                      {showNupayLink && (
                        <Box
                          className="link-button"
                          display="flex"
                          flexDirection="column"
                          color="white.default"
                          backgroundColor="primary.default"
                          onClick={() => {
                            onPayByNupay();
                            AmplitudeService.buttonClicked({
                              entity_name: "pay_with_nupay",
                              current_screen: "cc_checkout",
                              ...eventProps,
                            });
                          }}
                        >
                          <Typography
                            variant="caption"
                            color="primary.defaultL20"
                            strong
                          >
                            Cliente Nubank?
                          </Typography>
                          <Link
                            color="white.default"
                            variant="action"
                            href="##"
                            iconProps={{ name: "arrow-up-right" }}
                          >
                            Pague pelo app com benefícios
                          </Link>
                        </Box>
                      )}
                      <TextField
                        disabled={isSubmitting || showPopup}
                        syncValidations={{
                          required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                          isValidFullName:
                            Text.PAYMENT_VALIDATION_ERROR_FULL_NAME,
                          isValidFullNameCharacter:
                            Text.PAYMENT_VALIDATION_ERROR_CHARACTER_FULL_NAME,
                        }}
                        id="holderName"
                        name="holderName"
                        data-testid="holderName"
                        label={Text.PAYMENT_CARD_HOLDER_NAME_LABEL}
                      />
                      <Box
                        tag="section"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        gridGap={spacing("6x")}
                      >
                        <TextField
                          disabled={isSubmitting || showPopup}
                          syncValidations={{
                            required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                            isValidExpirationDate:
                              Text.PAYMENT_VALIDATION_ERROR_EXPIRATION_DATE,
                            isValidMonthExpirationDate:
                              Text.PAYMENT_VALIDATION_ERROR_MONTH_EXPIRATION_DATE,
                          }}
                          data-testid="expirationDate"
                          id="expirationDate"
                          inputmode="numeric"
                          type="tel"
                          name="expirationDate"
                          label={Text.PAYMENT_CARD_EXPIRATION_DATE_LABEL}
                          placeholder={
                            Text.PAYMENT_CARD_EXPIRATION_DATE_PLACEHOLDER
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              "expirationDate",
                              maskExpirationDate(e.target.value)
                            );
                          }}
                        />

                        <TextField
                          disabled={isSubmitting || showPopup}
                          syncValidations={{
                            required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                            isValidCardCVV: Text.PAYMENT_VALIDATION_ERROR_CVV,
                          }}
                          id="cvv"
                          inputmode="numeric"
                          type="tel"
                          maxLength="3"
                          name="cvv"
                          data-testid="cvv"
                          label={Text.PAYMENT_CARD_CVV_LABEL}
                        />
                      </Box>

                      {charging.installmentsOptions &&
                        charging.installmentsOptions.length > 1 &&
                        installmentSelected && (
                          <>
                            <TextField
                              id="installmentsNumber"
                              name="installmentsNumber"
                              data-testid="installments"
                              disabled={
                                isSubmitting || modalVisible || showPopup
                              }
                              label={Text.PAYMENT_INSTALLMENT_LABEL}
                              endIcon={<Icon name="chevron-down" />}
                              value={`${Text.PAYMENT_INSTALLMENT} ${installmentSelected.number}x ${installmentSelected.installment_amount}`}
                              onClick={() => {
                                AmplitudeService.buttonClicked({
                                  entity_name: "select_installments",
                                  current_screen: "cc_checkout",
                                  ...eventProps,
                                });
                                setModalVisible(true);
                              }}
                            />

                            <InstallmentsModal
                              visible={{ modalVisible, setModalVisible }}
                              installmentOptions={charging.installmentsOptions}
                              setInstallmentsNumber={setInstallmentsNumber}
                              eventProps={eventProps}
                            />
                          </>
                        )}
                    </Box>
                  </Box>

                  <Box display="grid" gridGap={spacing("6x")} marginBottom={32}>
                    <Box display="grid" gridGap={spacing("2x")}>
                      <Typography variant="heading4">
                        {Text.PAYMENT_CUSTOMER_TITLE}
                      </Typography>
                      <Typography variant="paragraph1" color="black.defaultT70">
                        {Text.PAYMENT_CUSTOMER_SUBTITLE}
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        disabled={isSubmitting || showPopup}
                        id="fullName"
                        name="fullName"
                        data-testid="fullName"
                        syncValidations={{
                          required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                          isValidFullName:
                            Text.PAYMENT_VALIDATION_ERROR_FULL_NAME,
                          isValidFullNameCharacter:
                            Text.PAYMENT_VALIDATION_ERROR_CHARACTER_FULL_NAME,
                        }}
                        label={Text.PAYMENT_CUSTOMER_FULL_NAME_LABEL}
                      />
                      <TextField
                        disabled={isSubmitting || showPopup}
                        syncValidations={{
                          required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                          email: Text.PAYMENT_VALIDATION_ERROR_EMAIL,
                        }}
                        id="email"
                        name="email"
                        data-testid="email"
                        label={Text.PAYMENT_CUSTOMER_EMAIL_LABEL}
                      />
                      <TextField
                        disabled={isSubmitting || showPopup}
                        id="taxId"
                        inputmode="numeric"
                        type="tel"
                        name="taxId"
                        data-testid="taxId"
                        maxLength="18"
                        syncValidations={{
                          required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                          isValidTaxId: Text.PAYMENT_VALIDATION_ERROR_TAXID,
                        }}
                        onChange={({
                          target: { value },
                        }: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("taxId", maskTaxId(value));
                        }}
                        label={Text.PAYMENT_CUSTOMER_TAXID_LABEL}
                      />
                      <TextField
                        disabled={isSubmitting || showPopup}
                        id="phone"
                        inputmode="numeric"
                        type="tel"
                        name="phone"
                        data-testid="phone"
                        maxLength="15"
                        syncValidations={{
                          required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                          isValidPhone: Text.PAYMENT_VALIDATION_ERROR_PHONE,
                        }}
                        onChange={({
                          target: { value },
                        }: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("phone", maskPhone(value));
                        }}
                        label={Text.PAYMENT_CUSTOMER_PHONE_LABEL}
                        placeholder={Text.PAYMENT_CUSTOMER_PHONE_PLACEHOLDER}
                      />

                      <Box>
                        <TextField
                          disabled={isSubmitting || showPopup}
                          id="postcode"
                          inputmode="numeric"
                          name="postcode"
                          data-testid="postcode"
                          maxLength="9"
                          syncValidations={{
                            required: Text.PAYMENT_VALIDATION_ERROR_REQUIRED,
                            isValidCep: Text.PAYMENT_VALIDATION_ERROR_CEP,
                          }}
                          onChange={({
                            target: { value },
                          }: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("postcode", maskCep(value));
                            getAddress(value);
                          }}
                          label={Text.PAYMENT_CUSTOMER_ADDRESS_CEP_LABEL}
                        />
                        <Typography
                          className="address-caption"
                          variant="caption"
                          color="success.default"
                          strong
                        >
                          {addressCaption}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Link
                    marginBottom={spacing("8x")}
                    variant="action"
                    href="https://nubank.com.br/contrato/politica-privacidade/"
                    target="_blank"
                    iconProps={{ name: "arrow-right" }}
                    onClick={() => {
                      AmplitudeService.buttonClicked({
                        entity_name: "terms",
                        current_screen: "cc_checkout",
                        ...eventProps,
                      });
                    }}
                  >
                    {Text.PAYMENT_TERMS_AND_CONDITIONS_LABEL}
                  </Link>

                  <Box
                    id="button-bottom-box"
                    display="flex"
                    justifyContent="center"
                  >
                    <div>
                      {!isSubmitting ? (
                        <Button
                          disabled={isDisabled || validateOnMount}
                          type="submit"
                          variant="contained"
                          styleVariant="primary"
                          data-testid="submit"
                          extended
                          iconProps={{ name: "arrow-right", title: "Pagar" }}
                        >
                          {Text.PAYMENT_PAY_LABEL}{" "}
                          {installmentSelected
                            ? installmentSelected.total_amount
                            : charging.amount}
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          styleVariant="primary"
                          extended
                        >
                          <Oval
                            ariaLabel="loading-indicator"
                            height={50}
                            width={30}
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                            color="#820AD1"
                            secondaryColor="white"
                          />
                        </Button>
                      )}
                    </div>
                  </Box>
                </>
              )}
            </Form.Step>
          </Form>
        </Box>
      </Container>
    </Layout>
  );
};

export default CardPayment;
